// Tip: NRP后台的code必须以pc-开头，小程序以mp-开头
// 注意事项： 请不要修改这个文件的任意一个【code】值和结构，目前顶部目录为三个[工作台 ｜ 业务中台 ｜ 系统设置]
// 如果增加了顶部目录，需要同步更改文件/store/modules/permission.js
export const topMenu = [
	{
		id: '1',
		code: 'dashboard',
		name: '工作台',
	},
	{
		id: '8',
		code: 'vehicle',
		name: '车辆中心',
		auth: 'pc/car-center',
	},
	{
		id: '9',
		code: 'driver',
		name: '司机中心',
		auth: 'pc/driver-center',
	},
	{
		id: '11',
		code: 'recruit',
		name: '招募中心',
		auth: 'pc/recruitment-center',
	},
	{
		id: '10',
		code: 'finance',
		name: '财务中心',
		auth: 'pc/finance-center',
	},
	{
		id: '2',
		code: 'risk',
		name: '风控中心',
		auth: 'pc/risk-center',
	},
	{
		id: '7',
		code: 'product',
		name: '商品中心',
		auth: 'pc/goods/finance-center',
	},
	{
		id: '4',
		code: 'chart',
		name: '数据中心',
		auth: 'pc/chart-center',
	},
	{
		id: '3',
		code: 'system',
		name: '系统设置',
		auth: 'pc/config-center',
	},
]

export const routeMenu = {
	dashboard: [
		{
			code: 'dashboard',
			name: '工作台',
			path: '/dashboard',
			source: 'user',
		},
	],
	vehicle: [
		{
			code: 'vehicle-management',
			name: '车辆管理',
			path: '/vehicle-management',
			source: 'user',
			auth: 'pc/car-center/car-manage',
		},
		{
			code: 'vehicle-check',
			name: '入库验车',
			path: '/vehicle-check',
			source: 'user',
			auth: 'pc/car-center/in-store',
		},
		{
			code: 'vehicle-delivery',
			name: '出库交车',
			path: '/vehicle-delivery',
			source: 'user',
			auth: 'pc/car-center/out-store',
		},
		{
			code: 'maintenance-management',
			name: '维保管理',
			path: '/maintenance-management',
			source: 'user',
			auth: 'pc/car-center/mainten-manage',
		},
		{
			code: 'violation-summary',
			name: '违章汇总',
			path: '/violation-summary',
			source: 'user',
			auth: 'pc/car-center/Illegal-record',
		},
		{
			code: 'violation-management',
			name: '违章明细',
			path: '/violation-management',
			source: 'user',
			auth: 'pc/car-center/Illegal-record',
		},

		{
			code: 'incident-management',
			name: '事故管理',
			path: '/incident-management',
			source: 'user',
			auth: 'pc/car-center/accident-manage',
		},
		{
			code: 'annual-inspection',
			name: '年检记录',
			path: '/annual-inspection',
			source: 'user',
			auth: 'pc/car-center/accident-manage',
		},
		{
			code: 'insurance-records',
			name: '保险记录',
			path: '/insurance-records',
			source: 'user',
			auth: 'pc/car-center/accident-manage',
		},
	],
	driver: [
		{
			code: 'driver-register',
			name: '注册司机',
			path: '/driver-register',
			source: 'user',
			auth: 'pc/driver-center/driver-register-manage',
		},
		{
			code: 'driver-management',
			name: '签约司机',
			path: '/driver-management',
			source: 'user',
			auth: 'pc/driver-center/driver-manage',
		},
		{
			code: 'company-customer',
			name: '公司客户',
			path: '/company-customer',
			source: 'user',
			auth: 'pc/driver-center/company-customer',
		},
		{
			code: 'driver-contract',
			name: '合同管理',
			path: '/driver-contract',
			source: 'user',
			auth: 'pc/driver-center/contract-manage',
		},
		{
			code: 'driver-rent',
			name: '账单列表',
			path: '/driver-rent',
			source: 'user',
			auth: 'pc/driver-center/rent-bill',
		},
		{
			code: 'reserve-car',
			name: '预约还车',
			path: '/reserve-car',
			source: 'user',
			auth: 'pc/driver-center/return-car',
		},
		{
			code: 'settle-car',
			name: '退车结算',
			path: '/settle-car',
			source: 'user',
			auth: 'pc/driver-center/order-settlement',
		},
		{
			code: 'renewal-list',
			name: '续签记录',
			path: '/renewal-list',
			source: 'user',
			auth: 'pc/driver-center/contract-renewal',
		},
		{
			code: 'delay-list',
			name: '合同延期',
			path: '/delay-list',
			source: 'user',
			auth: 'pc/driver-center/order-delay',
		},
	],
	finance: [
		{
			code: 'deduction-record',
			name: '抵扣记录',
			path: '/deduction-record',
			source: 'user',
			auth: 'pc/finance-center/deduction-record',
			componentName: 'DeductionRecord',
		},
	],
	product: [
		{
			code: 'product-management',
			name: '商品管理',
			path: '/product-management',
			source: 'user',
			auth: 'pc/goods/lease/paginate',
		},
	],
	recruit: [
		{
			code: 'apply-driver',
			name: '报名司机',
			path: '/apply-driver',
			source: 'user',
			auth: 'pc/recruitment-center/driver-clue',
		},
		{
			code: 'banner-setting',
			name: 'banner设置',
			path: '/banner-setting',
			source: 'user',
			auth: 'pc/recruitment-center/banner/page',
		},
	],
	risk: [
		{
			code: 'gps',
			name: 'GPS记录',
			path: '/gps',
			source: 'user',
			auth: 'pc/risk-center/gps-record',
		},
		{
			code: 'qianzu',
			name: '欠租时长',
			path: '/qianzu',
			source: 'user',
			auth: 'pc/risk-center/rent-arrears-time',
		},
		{
			code: 'daituiche',
			name: '待退车预警',
			path: '/daituiche',
			source: 'user',
			auth: 'pc/risk-center/return-car-warning',
		},
		{
			code: 'weizhangyujing',
			name: '违章预警',
			path: '/weizhangyujing',
			source: 'user',
			auth: 'pc/risk-center/Illegal-warning',
		},
		{
			code: 'shiguyujing',
			name: '事故预警',
			path: '/shiguyujing',
			source: 'user',
			auth: 'pc/risk-center/accident-warning',
		},
		{
			code: 'baoyangyujing',
			name: '保养预警',
			path: '/baoyangyujing',
			source: 'user',
			auth: 'pc/risk-center/maintain-warning',
		},
		{
			code: 'overTime',
			name: '超时维修预警',
			path: '/overTime',
			source: 'user',
			auth: 'pc/risk-center/overtime-repairs-warning',
		},
		{
			code: 'yunYingRed',
			name: '车辆运营红线',
			path: '/yunYingRed',
			source: 'user',
			auth: 'pc/risk-center/overtime-repairs-warning',
			// auth: 'pc/risk-center/warning-line',
		},
		{
			code: 'yyRedRules',
			name: '运营红线规则',
			path: '/yyRedRules',
			source: 'user',
			auth: 'pc/risk-center/overtime-repairs-warning',
			// auth: 'pc/risk-center/warning-line-rule',
		},
	],
	chart: [
		{
			code: 'chart-cockpit',
			name: '驾驶舱仪表盘',
			path: '/chart-cockpit',
			source: 'user',
			auth: 'pc/chart-center/chart-cockpit',
			chartLayout: true,
		},
		{
			code: 'chart-real',
			name: '实时数据',
			path: '/chart-real',
			source: 'user',
			auth: 'pc/chart-center/chart-real',
			chartLayout: true,
		},
		{
			code: 'chart-operate',
			name: '运营数据',
			path: '/chart-operate',
			source: 'user',
			auth: 'pc/chart-center/chart-operate',
			chartLayout: true,
		},
		{
			code: 'chart-driver-partner',
			name: '司服伙伴报表',
			path: '/chart-driver-partner',
			source: 'user',
			auth: 'pc/chart-center/chart-driver-partner',
		},
		{
			code: 'chart-maintainer',
			name: '维保专员报表',
			path: '/chart-maintainer',
			source: 'user',
			auth: 'pc/chart-center/chart-maintainer',
		},
		{
			code: 'chart-entry',
			name: '入库员报表',
			path: '/chart-entry',
			source: 'user',
			auth: 'pc/chart-center/chart-entry',
		},
		{
			code: 'chart-outbound',
			name: '出库员报表',
			path: '/chart-outbound',
			source: 'user',
			auth: 'pc/chart-center/chart-outbound',
		},
	],
	system: [
		{
			code: 'user-manage',
			name: '用户管理',
			path: '/user-manage',
			source: 'user',
			auth: 'pc/config-center/biz-user',
		},
		{
			code: 'account',
			name: '账号管理',
			path: '/account',
			source: 'user',
			auth: 'pc/config-center/account-warning',
		},
		{
			code: 'role',
			name: '角色管理',
			path: '/role',
			source: 'user',
			auth: 'pc/config-center/role-warning',
		},
		{
			code: 'data-auth',
			name: '数据权限',
			path: '/data-auth',
			source: 'user',
			auth: 'pc/config-center/data-permission',
		},
		{
			code: 'models',
			name: '车型管理',
			path: '/models',
			source: 'user',
			auth: 'pc/config-center/car-model-warning',
		},
		{
			code: 'record-menu',
			name: '业务记录',
			path: '/record-menu',
			children: [
				{
					code: 'sms-record',
					name: '短信记录',
					path: '/sms-record',
					source: 'user',
					auth: 'pc/config-center/sms-record',
				},
				{
					code: 'wxpublic-record',
					name: '公众号记录',
					path: '/wxpublic-record',
					source: 'user',
					auth: 'pc/config-center/mp-record',
				},
				{
					code: 'dingtalk-record',
					name: '钉钉记录',
					path: '/dingtalk-record',
					source: 'user',
					auth: 'pc/config-center/dintalk-record',
				},
			],
		},
		{
			code: 'basic-menu',
			name: '基础信息',
			path: '/basic-menu',
			children: [
				{
					code: 'platform-channel',
					name: '平台渠道',
					path: '/platform-channel',
					source: 'user',
					auth: 'pc/config-center/platform-warning',
				},
				{
					code: 'maintenance-shop',
					name: '维修厂',
					path: '/maintenance-shop',
					source: 'user',
					auth: 'pc/config-center/maintain-factory',
				},
				{
					code: 'car-store',
					name: '车辆存放',
					path: '/car-store',
					source: 'user',
					auth: 'pc/config-center/car-store',
				},
				// {
				// 	code: 'order-type',
				// 	name: '订单来源',
				// 	path: '/order-type',
				// 	source: 'user',
				// 	auth: 'pc/config-center/order-source',
				// },
				{
					code: 'maintenance-type',
					name: '维修项目',
					path: '/maintenance-type',
					source: 'user',
					auth: 'pc/config-center/repair-project',
				},
				{
					code: 'insurance-company',
					name: '保险公司',
					path: '/insurance-company',
					source: 'user',
					auth: 'pc/config-center/insurance-company',
				},
				{
					code: 'enterprise-list',
					name: '子公司维护',
					path: '/enterprise-list',
					source: 'user',
					auth: 'pc/config-center/comany-manage',
				},
			],
		},
	],
}

<template>
	<div class="yi-list-layout" v-loading="loading">
		<div class="layout-title">
			<slot name="title"></slot>
		</div>
		<div class="layout-filter">
			<slot name="filter"></slot>
		</div>
		<div class="layout-table">
			<slot name="table"></slot>
		</div>
		<div class="layout-footer">
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'YiListLayout',
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	setup() {},
})
</script>

<style lang="less" scoped>
.yi-list-layout {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	box-sizing: border-box;

	.layout-title {
		width: 100%;
		flex: 0 0 54px;
	}

	.layout-filter {
		width: 100%;
		flex: 0 0 0;
	}

	.layout-table {
		width: 100%;
		flex: 1 0 100px;
		margin-top: 10px;
		min-height: 100px;
	}

	.layout-footer {
		width: 100%;
		flex: 0 0 50px;
	}
}
</style>

import { usePermissionStore } from '@/store/modules/permission'
import { useUserStore } from '@/store/modules/user'

function checkArray(key) {
	const userStore = useUserStore()
	const { isAdmin } = userStore.userInfo
	if (isAdmin) return true
	const permissionStore = usePermissionStore()
	const permission = permissionStore.authList
	const permissionIndex = permission.findIndex(p => p.code === key)
	if (permissionIndex !== -1) {
		return true
	} else {
		return false
	}
}

export const permissionDirective = {
	mounted(el, binding) {
		let permission = binding.value
		if (permission) {
			let hasPermission = checkArray(permission)
			if (!hasPermission) {
				el.parentNode && el.parentNode.removeChild(el)
			}
		}
	},
}

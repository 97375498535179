<template>
	<div class="filter-item" v-if="type === 'search'">
		<span class="filter-item-label">{{ label }}</span>
		<el-input
			class="filter-item-input"
			v-model="filterValue"
			:style="styleComp"
			:placeholder="placeholder"
			:clearable="clearable"
			:disabled="disabled"
			@input="onSearchInputChange"
			@keyup.enter="onInputEnter"
		/>
	</div>
	<div class="filter-item" v-else-if="type === 'enhancement'">
		<span class="filter-item-label">{{ label }}</span>
		<el-autocomplete
			v-model="filterValue"
			class="filter-item-input"
			:placeholder="placeholder"
			:fetch-suggestions="options"
			:clearable="clearable"
			:disabled="disabled"
			@input="onChange"
			@select="onEnhancementChange"
		/>
	</div>
	<div class="filter-item" v-else-if="type === 'select'">
		<span class="filter-item-label">{{ label }}</span>
		<el-select
			v-model="filterValue"
			class="filter-item-input"
			:placeholder="placeholder"
			:filterable="filterable"
			:clearable="clearable"
			:disabled="disabled"
			@change="onChange"
		>
			<el-option
				v-for="item in selectOptions"
				:key="item.code"
				:label="item.name"
				:value="item.code"
			/>
		</el-select>
	</div>
	<div class="filter-item" v-else-if="type === 'number'">
		<span class="filter-item-label">{{ label }}</span>
		<el-input-number
			v-model="filterValue"
			class="filter-item-input"
			controls
			controls-position="right"
			step="1"
			step-strictly
			min="0"
			max="9999"
			:clearable="clearable"
			:disabled="disabled"
			@change="onChange"
		/>
	</div>
	<div class="filter-item" v-else-if="type === 'dynamic-select'">
		<span class="filter-item-label">{{ label }}</span>
		<el-select
			v-model="filterValue"
			class="filter-item-input"
			:placeholder="placeholder"
			:remote-method="dynamicSelectRemoteMethod"
			:loading="isLoading"
			:clearable="clearable"
			:disabled="disabled"
			filterable
			remote
			reserve-keyword
			@focus="onDynamicFocus"
			@change="onChange"
		>
			<el-option
				v-for="item in remoteOptions"
				:key="item.code"
				:label="item.name"
				:value="item.code"
			/>
		</el-select>
	</div>
	<div class="filter-item" v-else-if="type === 'cascader'">
		<span class="filter-item-label">{{ label }}</span>
		<el-cascader
			v-model="filterValue"
			class="filter-item-input"
			:show-all-levels="false"
			:props="cascaderProps"
			:options="options"
			:placeholder="placeholder"
			:clearable="clearable"
			:disabled="disabled"
			@change="onChange"
		/>
	</div>
	<div class="filter-item" v-else-if="type === 'cascader-plus'">
		<span class="filter-item-label">{{ label }}</span>
		<el-cascader
			v-model="filterValue"
			class="filter-item-input"
			:show-all-levels="false"
			:props="cascaderPlusProps"
			:options="options"
			:placeholder="placeholder"
			:clearable="clearable"
			:disabled="disabled"
			@change="onChange"
		/>
	</div>
	<div style="margin-right: 15px; margin-bottom: 15px" v-else-if="type === 'multi-select'">
		<span class="filter-item-label">{{ label }}</span>
		<el-select
			v-model="filterValue"
			class="filter-item-input"
			:style="styleComp"
			:placeholder="placeholder"
			:clearable="clearable"
			:disabled="disabled"
			:filterable="filterable"
			multiple
			collapse-tags
			@change="onChange"
		>
			<el-option
				v-for="item in selectOptions"
				:key="item.code"
				:label="item.name"
				:value="item.code"
			/>
		</el-select>
	</div>
	<div class="filter-item" v-else-if="type === 'date' || type === 'time'">
		<span class="filter-item-label">{{ label }}</span>
		<el-date-picker
			v-model="filterValue"
			type="daterange"
			align="right"
			format="YYYY-MM-DD"
			value-format="YYYY-MM-DD"
			range-separator="至"
			:start-placeholder="leftText"
			:end-placeholder="rightText"
			:disabled-date="onDisabledDate"
			:shortcuts="shortcuts"
			:disabled="disabled"
			unlink-panels
			@change="onDateChange"
			@calendar-change="onCalendarChange"
		/>
	</div>
	<div class="filter-item" v-else-if="type === 'month'">
		<span class="filter-item-label">{{ label }}</span>
		<el-date-picker
			v-model="filterValue"
			type="month"
			align="right"
			format="YYYY-MM"
			value-format="YYYY-MM"
			:clearable="clearable"
			:placeholder="placeholder"
			:disabled-date="onDisabledDate"
			:shortcuts="shortcuts"
			:disabled="disabled"
			unlink-panels
			@change="onDateChange"
			@calendar-change="onCalendarChange"
		/>
	</div>
	<div class="filter-item" v-else-if="type === 'dateTime'">
		<span class="filter-item-label">{{ label }}</span>
		<el-date-picker
			v-model="filterValue"
			type="datetimerange"
			align="right"
			format="YYYY-MM-DD HH:mm:ss"
			value-format="YYYY-MM-DD HH:mm:ss"
			range-separator="至"
			:start-placeholder="leftText"
			:end-placeholder="rightText"
			:shortcuts="shortcuts"
			:disabled="disabled"
			unlink-panels
			@change="onChange"
		/>
	</div>
	<div class="filter-item" v-else-if="type === 'input-range'">
		<span class="filter-item-label">{{ label }}</span>
		<el-input
			class="filter-item-input"
			type="number"
			:model-value="inputMin"
			:style="styleComp"
			:placeholder="placeholder"
			:clearable="clearable"
			:disabled="disabled"
			@input="onInputMin"
			@keyup.enter="onInputEnter"
			style="width: 120px"
		/>
		~
		<el-input
			class="filter-item-input"
			type="number"
			:model-value="inputMax"
			:style="styleComp"
			:placeholder="placeholder"
			:clearable="clearable"
			:disabled="disabled"
			@input="onInputMax"
			@keyup.enter="onInputEnter"
			style="width: 120px"
		/>
	</div>
</template>

<script>
import dayjs from 'dayjs'
import { defineComponent, reactive, toRefs, ref, watch, computed } from 'vue'
import { useDict } from '@/utils/hooks.js'
export default defineComponent({
	name: 'YiFilterItem',
	props: {
		modelValue: {
			type: [String, Number, Array],
			default: '',
		},
		attr: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'select',
		},
		label: {
			type: String,
			default: '',
		},
		filterable: {
			type: Boolean,
			default: true,
		},
		clearable: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: '请筛选',
		},
		leftText: {
			type: String,
			default: '开始日期',
		},
		rightText: {
			type: String,
			default: '结束日期',
		},
		options: {
			type: [Array, Function],
			default: () => {
				return []
			},
		},
		extraOptions: {
			type: Object,
			default: () => {},
		},
		shortcuts: {
			type: Array,
			default: () => {
				return [
					{
						text: '最近一周',
						value: () => {
							const current = new Date()
							const start = current.getTime() - 3600 * 1000 * 24 * 7
							return [start, current]
						},
					},
					{
						text: '最近一个月',
						value: () => {
							const current = new Date()
							const start = current.getTime() - 3600 * 1000 * 24 * 30
							return [start, current]
						},
					},
					{
						text: '最近三个月',
						value: () => {
							const current = new Date()
							const start = current.getTime() - 3600 * 1000 * 24 * 90
							return [start, current]
						},
					},
				]
			},
		},
		disabledDateConfig: {
			type: Object,
			default: () => {
				return {
					enable: false, // 开关 false: 不使用 true：可使用
					days: 30, // 可选择的天数
					type: 'select', // select：选中第一个时间  current: 当前日期
					position: 'after', // before：选择日期或当前日期之前 after：选择日期或当前日期之后
				}
			},
		},
		width: {
			type: Number,
			default: 200,
		},
	},
	components: {},
	emits: ['singleChange', 'submit'],
	setup(props, context) {
		const customOptions = ref({})
		const state = reactive({
			isLoading: false,
			cascaderProps: {
				value: 'code',
				label: 'name',
				emitPath: false,
			},
			cascaderPlusProps: {
				value: props.extraOptions?.valueText || 'code',
				label: props.extraOptions?.labelText || 'name',
				emitPath: false,
				checkStrictly: true,
			},
			remoteOptions: [],
			filterValue: ref(props.modelValue),
			selectedDate: [],
			inputMin: null,
			inputMax: null,
		})

		watch(
			() => props.modelValue,
			nValue => {
				state.filterValue = nValue
				if (props.type === 'input-range') {
					if (!nValue) {
						state.inputMin = null
						state.inputMax = null
					}
				}
			},
		)

		const styleComp = computed(() => {
			return `width: ${props.width}px`
		})
		const selectOptions = computed(() => {
			return customOptions.value[props.attr] ? customOptions.value[props.attr] : props.options
		})

		const methods = {
			onEnhancementChange(event) {
				methods.onChange(event.value)
			},
			onSearchInputChange(event) {
				methods.onChange(event.trim())
			},
			onChange(event) {
				context.emit('singleChange', {
					attr: props.attr,
					value: event,
				})
			},
			onCalendarChange(event) {
				state.selectedDate = event.map(item => new Date(item).getTime())
			},
			onDisabledDate(time) {
				if (!props.disabledDateConfig.enable) return
				if (state.selectedDate[0] && state.selectedDate[1]) return
				if (props.disabledDateConfig.type === 'select' && !state.selectedDate[0]) return

				const dayNum = Number(props.disabledDateConfig.days)
				if (!dayNum) return

				let disabledDate = false
				let timeStamp = 0

				if (props.disabledDateConfig.type === 'select') {
					timeStamp = state.selectedDate[0]
				} else {
					timeStamp = new Date(dayjs(new Date()).format('YYYY-MM-DD') + ' 00:00:00').getTime()
				}

				if (props.disabledDateConfig.position === 'before') {
					disabledDate =
						time.getTime() > timeStamp || time.getTime() < timeStamp - 3600 * 1000 * 24 * dayNum
				} else {
					disabledDate =
						time.getTime() > timeStamp + 3600 * 1000 * 24 * dayNum || time.getTime() < timeStamp
				}

				return disabledDate
			},
			onDateChange(event) {
				if (!event || !event.length) {
					methods.onChange(null)
					return
				}
				if (props.type === 'date') {
					methods.onChange([`${event[0]} 00:00:00`, `${event[1]} 23:59:59`])
				} else if (props.type === 'month') {
					methods.onChange(event + ' 00:00:00')
				} else {
					methods.onChange([`${event[0]} 00:00:00`, `${event[1]} 23:59:59`])
				}
			},
			onDynamicFocus() {
				if (!props.modelValue) {
					methods.dynamicSelectRemoteMethod('')
				}
			},
			dynamicSelectRemoteMethod(keyword) {
				state.isLoading = true
				props.options(keyword, options => {
					state.isLoading = false
					state.remoteOptions = options
				})
			},
			onInputEnter() {
				context.emit('submit')
			},
			onInputMin(val) {
				state.inputMin = val
				methods.onChange([val, state.inputMax])
			},
			onInputMax(val) {
				state.inputMax = val
				methods.onChange([state.inputMin, val])
			},
			async mapOptions() {
				const data = await props.options()
				customOptions.value[props.attr] = data
			},
		}
		if (typeof props.options == 'function') {
			methods.mapOptions()
		}
		return {
			...toRefs(state),
			...methods,
			styleComp,
			selectOptions,
		}
	},
})
</script>

<style lang="less" scoped>
.filter-item {
	height: 32px;
	margin-right: 15px;
	margin-bottom: 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;

	.filter-item-input {
		width: 195px;
	}
}
.filter-item-label {
	font-family: PingFangSC-Regular;
	font-size: 12px;
	font-weight: 400;
	color: #a0a0a0;
	line-height: 14px;
	margin-right: 5px;
}
</style>

const financeRoutes = [
	{
		path: '/deduction-record',
		component: () => import('@/views/finance/deduction'),
		name: 'DeductionRecord',
		meta: {
			name: 'DeductionRecord',
			title: '抵扣记录',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/finance-center/deduction-record',
		},
	},
]

export default financeRoutes

import { validatenull } from './validate'
import { useDictStore } from '@/store/modules/dict'

//表单序列化
export const serialize = data => {
	let list = []
	Object.keys(data).forEach(ele => {
		list.push(`${ele}=${data[ele]}`)
	})
	return list.join('&')
}

export const getObjType = obj => {
	var toString = Object.prototype.toString
	var map = {
		'[object Boolean]': 'boolean',
		'[object Number]': 'number',
		'[object String]': 'string',
		'[object Function]': 'function',
		'[object Array]': 'array',
		'[object Date]': 'date',
		'[object RegExp]': 'regExp',
		'[object Undefined]': 'undefined',
		'[object Null]': 'null',
		'[object Object]': 'object',
	}
	if (obj instanceof Element) {
		return 'element'
	}
	return map[toString.call(obj)]
}
export const getViewDom = () => {
	return window.document.getElementById('avue-view').getElementsByClassName('el-scrollbar__wrap')[0]
}
/**
 * 对象深拷贝
 */
export const deepClone = data => {
	var type = getObjType(data)
	var obj
	if (type === 'array') {
		obj = []
	} else if (type === 'object') {
		obj = {}
	} else {
		//不再具有下一层次
		return data
	}
	if (type === 'array') {
		for (var i = 0, len = data.length; i < len; i++) {
			obj.push(deepClone(data[i]))
		}
	} else if (type === 'object') {
		for (var key in data) {
			obj[key] = deepClone(data[key])
		}
	}
	return obj
}
/**
 * 设置灰度模式
 */
export const toggleGrayMode = status => {
	if (status) {
		document.body.className = document.body.className + ' grayMode'
	} else {
		document.body.className = document.body.className.replace(' grayMode', '')
	}
}

/**
 * 加密处理
 */
export const encryption = params => {
	let { data, type, param, key } = params
	let result = JSON.parse(JSON.stringify(data))
	if (type == 'Base64') {
		param.forEach(ele => {
			result[ele] = btoa(result[ele])
		})
	} else if (type == 'Aes') {
		param.forEach(ele => {
			result[ele] = window.CryptoJS.AES.encrypt(result[ele], key).toString()
		})
	}
	return result
}

/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
	if (fullscreenEnable()) {
		exitFullScreen()
	} else {
		reqFullScreen()
	}
}
/**
 * esc监听全屏
 */
export const listenfullscreen = callback => {
	function listen() {
		callback()
	}

	document.addEventListener('fullscreenchange', function () {
		listen()
	})
	document.addEventListener('mozfullscreenchange', function () {
		listen()
	})
	document.addEventListener('webkitfullscreenchange', function () {
		listen()
	})
	document.addEventListener('msfullscreenchange', function () {
		listen()
	})
}
/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
	var isFullscreen =
		document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen
	return isFullscreen
}

/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
	if (document.documentElement.requestFullScreen) {
		document.documentElement.requestFullScreen()
	} else if (document.documentElement.webkitRequestFullScreen) {
		document.documentElement.webkitRequestFullScreen()
	} else if (document.documentElement.mozRequestFullScreen) {
		document.documentElement.mozRequestFullScreen()
	}
}
/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
	if (document.documentElement.requestFullScreen) {
		document.exitFullScreen()
	} else if (document.documentElement.webkitRequestFullScreen) {
		document.webkitCancelFullScreen()
	} else if (document.documentElement.mozRequestFullScreen) {
		document.mozCancelFullScreen()
	}
}
/**
 * 递归寻找子类的父类
 */

export const findParent = (menu, id) => {
	for (let i = 0; i < menu.length; i++) {
		if (menu[i].children.length != 0) {
			for (let j = 0; j < menu[i].children.length; j++) {
				if (menu[i].children[j].id == id) {
					return menu[i]
				} else {
					if (menu[i].children[j].children.length != 0) {
						return findParent(menu[i].children[j].children, id)
					}
				}
			}
		}
	}
}
/**
 * 判断2个对象属性和值是否相等
 */

/**
 * 动态插入css
 */

export const loadStyle = url => {
	const link = document.createElement('link')
	link.type = 'text/css'
	link.rel = 'stylesheet'
	link.href = url
	const head = document.getElementsByTagName('head')[0]
	head.appendChild(link)
}
/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
	delete obj1.close
	var o1 = obj1 instanceof Object
	var o2 = obj2 instanceof Object
	if (!o1 || !o2) {
		/*  判断不是对象  */
		return obj1 === obj2
	}

	if (Object.keys(obj1).length !== Object.keys(obj2).length) {
		return false
		//Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
	}

	for (var attr in obj1) {
		var t1 = obj1[attr] instanceof Object
		var t2 = obj2[attr] instanceof Object
		if (t1 && t2) {
			return diff(obj1[attr], obj2[attr])
		} else if (obj1[attr] !== obj2[attr]) {
			return false
		}
	}
	return true
}
/**
 * 根据字典的value显示label
 */
export const findByvalue = (dic, value) => {
	let result = ''
	if (validatenull(dic)) return value
	if (typeof value == 'string' || typeof value == 'number' || typeof value == 'boolean') {
		let index = 0
		index = findArray(dic, value)
		if (index != -1) {
			result = dic[index].label
		} else {
			result = value
		}
	} else if (value instanceof Array) {
		result = []
		let index = 0
		value.forEach(ele => {
			index = findArray(dic, ele)
			if (index != -1) {
				result.push(dic[index].label)
			} else {
				result.push(value)
			}
		})
		result = result.toString()
	}
	return result
}
/**
 * 根据字典的value查找对应的index
 */
export const findArray = (dic, value) => {
	for (let i = 0; i < dic.length; i++) {
		if (dic[i].value == value) {
			return i
		}
	}
	return -1
}
/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
	let random = ''
	random = Math.ceil(Math.random() * 100000000000000)
		.toString()
		.substr(0, len ? len : 4)
	if (date) random = random + Date.now()
	return random
}

export const randomLenString = len => {
	var s = []
	var hexDigits = '0123456789abcdef'
	for (var i = 0; i <= len; i++) {
		s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
	}
	s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
	s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
	s[8] = s[13] = s[18] = s[23]
	var uuid = s.join('')
	return uuid
}

/**
 * 打开小窗口
 */
export const openWindow = (url, title, w, h) => {
	// Fixes dual-screen position                            Most browsers       Firefox
	const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left
	const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top

	const width = window.innerWidth
		? window.innerWidth
		: document.documentElement.clientWidth
		? document.documentElement.clientWidth
		: screen.width
	const height = window.innerHeight
		? window.innerHeight
		: document.documentElement.clientHeight
		? document.documentElement.clientHeight
		: screen.height

	const left = width / 2 - w / 2 + dualScreenLeft
	const top = height / 2 - h / 2 + dualScreenTop
	const newWindow = window.open(
		url,
		title,
		'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' +
			w +
			', height=' +
			h +
			', top=' +
			top +
			', left=' +
			left,
	)

	// Puts focus on the newWindow
	if (window.focus) {
		newWindow.focus()
	}
}

/**
 * 获取顶部地址栏地址
 */
export const getTopUrl = () => {
	return window.location.href.split('/#/')[0]
}

/**
 * 获取url参数
 * @param name 参数名
 */
export const getQueryString = name => {
	let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
	let r = window.location.search.substr(1).match(reg)
	if (r != null) return unescape(decodeURI(r[2]))
	return null
}

/**
 * 从指定字符串中获取url参数
 * @param name 参数名
 * Lihao 20201123
 */
export function getQueryStringByTarget(target, name) {
	const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
	const url = decodeURI(target)
	const search = url.substring(url.lastIndexOf('?') + 1)
	const r = search.match(reg)
	if (r != null) return unescape(r[2])
	return null
}

export function convertUrl(url) {
	let result
	try {
		result = decodeURIComponent(url.replace(/%25/g, '%'))
	} catch (e) {
		result = url
	} finally {
		// eslint-disable-next-line no-unsafe-finally
		return result
	}
}
//将base64转换为文件
export function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	return new File([u8arr], filename, { type: mime })
}

//富文本反转义html
export const escape2Html = str => {
	var arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' }
	return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
		return arrEntities[t]
	})
}

//富文本转义html
export const html2Escape = str => {
	return str.replace(/[<>&"]/g, function (c) {
		return { '<': '&lt;', '>': '&gt;', '&': '&amp;', '"': '&quot;' }[c]
	})
}
//找出两个数组中的不同
export const arrDiff = (fullArr, restArr) => {
	let arr = []
	fullArr.map(item => {
		let flag = false
		restArr.map(data => {
			if (item.id == data.id) {
				flag = true
			}
		})
		!flag && arr.push(item)
	})
	return arr
}
//将文件转成base64
export function getBase64(file) {
	return new Promise(function (resolve, reject) {
		let reader = new FileReader()
		let imgResult = ''
		reader.readAsDataURL(file)
		reader.onload = function () {
			imgResult = reader.result
		}
		reader.onerror = function (error) {
			reject(error)
		}
		reader.onloadend = function () {
			resolve(imgResult)
		}
	})
}
//获取base64字符串的大小
export function getBase64Size(base64) {
	if (base64) {
		// 获取base64图片byte大小
		base64 = base64.split(',')[1].split('=')[0]
		var strLength = base64.length
		var fileLength = strLength - (strLength / 8) * 2
		return Math.floor(fileLength) // 向下取整
	} else {
		return null
	}
}

/**
 * 获取地址栏参数对象
 * @param url 地址
 * @returns { object } 参数对象
 */
export function getQueryObject(url) {
	url = url == null ? window.location.href : decodeURIComponent(url)
	const search = url.substring(url.lastIndexOf('?') + 1)
	const obj = {}
	const reg = /([^?&=]+)=([^?&=]*)/g
	search.replace(reg, (rs, $1, $2) => {
		const name = decodeURIComponent($1)
		let val = decodeURIComponent($2)
		val = String(val)
		obj[name] = val
		return rs
	})
	return obj
}

/**
 * 获取指定地址栏参数值
 * @param param 参数 key
 * @returns {string} 参数值
 */
export function getParameter(param) {
	const query = window.location.search || window.location.hash
	const iLen = param.length
	let iStart = query.indexOf(param)
	if (iStart === -1) return ''
	iStart += iLen + 1
	const iEnd = query.indexOf('&', iStart)

	if (iEnd === -1) return query.substring(iStart)
	return query.substring(iStart, iEnd)
}

/**
 * 函数防抖：触发高频事件 N 秒后只会执行一次，如果 N 秒内事件再次触发，则会重新计时。
 * @param func 需要执行的函数
 * @param wait 延迟执行时间
 * @param immediate 是否立即执行一次
 * @returns {string} 参数值
 */
export function debounce(func, wait, immediate = false) {
	var timeout, result

	var debounced = function () {
		var context = this
		var args = arguments

		if (timeout) clearTimeout(timeout)
		if (immediate) {
			// 如果已经执行过，不再执行
			var callNow = !timeout
			timeout = setTimeout(function () {
				timeout = null
			}, wait)
			if (callNow) result = func.apply(context, args)
		} else {
			timeout = setTimeout(function () {
				func.apply(context, args)
			}, wait)
		}
		return result
	}

	debounced.cancel = function () {
		clearTimeout(timeout)
		timeout = null
	}

	return debounced
}

/**
 * 利用 document.title 做新消息提示
 * @export
 * @param {Number} count
 */
export function titleNotify(count) {
	const hasNewMessage = count > 0
	if (hasNewMessage) {
		if (document.title.search(/\((.*?)\)/) >= 0) {
			document.title = document.title.replace(/\((.*?)\)/, `(新消息+${count > 99 ? '99+' : count})`)
		} else {
			document.title = `(新消息+${count})${document.title}`
		}
	} else {
		document.title = document.title.replace(/\((.*?)\)/, '')
	}
}

// 格式化区域数据 到县市区
export function formatArea(areaList) {
	return areaList.map(province => {
		const provinceObj = {
			name: province.title,
			code: province.value,
		}
		if (province.children && province.children.length > 0) {
			provinceObj.children = province.children.map(city => {
				const cityObj = {
					name: city.title,
					code: city.value,
				}
				if (city.children && province.children.length > 0) {
					cityObj.children = city.children.map(area => {
						return {
							name: area.title,
							code: area.value,
						}
					})
				}
				return cityObj
			})
		}
		return provinceObj
	})
}

// 格式化区域数据 到市级
export function formatCity(areaList) {
	return areaList.map(item => {
		const provinceObj = {
			code: item.code,
			name: item.name,
		}
		if (item.children && item.children.length > 0) {
			provinceObj.children = item.children.map(city => {
				return {
					code: city.code,
					name: city.name,
				}
			})
		}
		return provinceObj
	})
}

// 格式化区域数据 到省级
export function formatProvince(areaList) {
	return areaList.map(item => {
		return {
			code: item.code,
			name: item.name,
		}
	})
}

// 枚举类数据取name值
export const getNameFromEnums = (key, value) => {
	const dictStore = useDictStore()
	let enums = dictStore.enums
	// 枚举中为字符串, value中为字符串或者整型, 故用两个等于
	let findEnum = (enums[key] || []).find(item => item.code == value)
	return findEnum ? findEnum.name : '--'
}

export function handleExport(data, name) {
	const blob = new Blob([data], {
		type: 'application/vnd.ms-excel',
	})

	const link = document.createElement('a')
	const current = new Date()
	link.download = `${name}-${current.toLocaleDateString()}.xlsx`
	link.href = window.URL.createObjectURL(blob)
	document.body.appendChild(link)
	link.click()
	window.URL.revokeObjectURL(link.href)
}

const whitelist = [
	{
		path: '/login',
		component: () => import('@/views/login/index'),
		name: 'Login',
	},
	{
		path: '/lock',
		component: () => import('@/views/system/lock/index'),
		name: 'Lock',
	},
	{
		path: '/:path(.*)',
		component: () => import('@/views/error/no-found/index'),
		name: 'noFound',
	},
]

export default whitelist

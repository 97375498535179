<template>
	<div class="yi-filter-box">
		<template v-for="(filter, index) in setting.filters">
			<filter-item
				v-if="!filter.isCustom"
				v-model="filterParams[filter.attr]"
				v-show="getShowStatus(index)"
				v-bind="filter"
				:key="filter.attr"
				:type="filter.type"
				:placeholder="filter.placeholder"
				:options="dicts[filter.dictKey] || filter.options"
				:attr="filter.attr"
				:filterable="filter.filterable"
				:clearable="filter.clearable"
				:disabled="filter.disabled"
				:extra-options="filter.extraOptions || {}"
				@single-change="onSingleChange"
				@submit="submit"
			/>
			<template v-else>
				<component
					v-if="filter.component"
					v-model="filterParams[filter.attr]"
					v-show="getShowStatus(index)"
					v-bind="filter"
					:is="filter.component"
					:key="filter.attr"
					:type="filter.type"
					:placeholder="filter.placeholder"
					:options="filter.options"
					:attr="filter.attr"
					:filterable="filter.filterable"
					:clearable="filter.clearable"
					:disabled="filter.disabled"
					:extra-options="filter.extraOptions || {}"
					@single-change="onSingleChange"
				/>
				<slot :name="filter.attr" :filterParams="filterParams"></slot>
			</template>
		</template>
		<div v-if="showAfterFilterDividerComp" class="divider" />
		<el-button class="filter-box-button" type="primary" @click="submit">查询</el-button>
		<el-button class="filter-box-button" @click="reset">清空条件</el-button>
		<div v-if="showAfterQueryDividerComp" class="divider" />
		<div
			class="dropdown-button"
			:class="dropdownButtonClassComp"
			v-if="showAfterQueryDividerComp"
			@click="onShrink"
		>
			<el-icon><CaretTop /></el-icon>
		</div>
		<!-- <img
			v-if="showAfterQueryDividerComp"
			src="https://websitedn.yiautos.com/ymc_icon/web_drop_down.png"
			class="dropdown-button"
			:class="dropdownButtonClassComp"
			@click="onShrink"
		/> -->
	</div>
</template>

<script>
import { defineComponent, reactive, toRefs, computed, onMounted } from 'vue'
import FilterItem from './Item'
import { useDict } from '@/utils/hooks.js'
import { useDictStore } from '@/store/modules/dict'

export default defineComponent({
	name: 'YiFilter',
	components: {
		FilterItem,
	},
	emits: ['singleChange', 'change', 'reset', 'change-state'],
	props: {
		setting: {
			type: Object,
			required: true,
		},
	},
	setup(props, context) {
		const state = reactive({
			isReady: false,
			isShrink: true,
			dicts: {},
			singleChangeAttr: '',
			originalParams: [],
			filterParams: {},
		})

		state.showAfterFilterDividerComp = computed(() => {
			return props.setting.filters && props.setting.filters.length > 0
		})

		state.showAfterQueryDividerComp = computed(() => {
			return props.setting.expand
		})

		state.dropdownButtonClassComp = computed(() => {
			return {
				'dropdown-button-shrink': state.isShrink,
			}
		})
		const methods = {
			setDefaultValue(isReset = false) {
				if (isReset) {
					state.filterParams = {
						...state.originalParams,
					}
				} else {
					props.setting.filters &&
						props.setting.filters.forEach(item => {
							state.filterParams[item.attr] = item?.value
							state.originalParams = {
								...state.filterParams,
							}
						})
				}
			},
			dealAssocClear() {
				props.setting.filters &&
					props.setting.filters.forEach(item => {
						if (item.attr === state.singleChangeAttr) {
							if (typeof item.assocClear === 'string') {
								state.filterParams[item.assocClear] = null
							}

							if (item.assocClear instanceof Array) {
								item.assocClear.forEach(assocItem => {
									state.filterParams[assocItem] = null
								})
							}
						}
					})
			},
			onShrink() {
				state.isShrink = !state.isShrink
			},
			getShowStatus(index) {
				if (!state.isShrink) {
					return true
				} else {
					return index < 3
				}
			},
			setFilterData(attr, value) {
				state.filterParams[attr] = value
			},
			onSingleChange(event) {
				state.singleChangeAttr = event.attr
				state.filterParams[event.attr] = event.value
				methods.dealAssocClear()
				context.emit('singleChange', event)
			},
			reset() {
				methods.setDefaultValue(true)
				methods.submit()
				context.emit('reset')
			},
			submit() {
				context.emit('change', state.filterParams)
			},
			manualSetParams(params, needRefresh = true) {
				Object.keys(params).forEach(key => {
					state.filterParams[key] = params[key]
				})
				if (needRefresh) {
					setTimeout(() => {
						context.emit('change', state.filterParams)
					}, 1000)
				}
			},
			async fetchDicts() {
				const list = props.setting.filters.filter(item => item.dictKey)
				const keys = list.map(item => item.dictKey)
				state.dicts = await useDict(keys)
				const store = useDictStore()
				store.setDict(state.dicts)
			},
		}

		onMounted(() => {
			methods.setDefaultValue()
			methods.fetchDicts()
		})

		return {
			...toRefs(state),
			...methods,
		}
	},
})
</script>

<style lang="less" scoped>
.yi-filter-box {
	width: 100%;
	background-color: #f5f5f5;
	padding-left: 15px;
	padding-top: 15px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	box-sizing: border-box;
	flex-wrap: wrap;
	.filter-box-button {
		margin-left: 0;
		margin-right: 15px;
		margin-bottom: 15px;
	}
	.dropdown-button {
		width: 30px;
		height: 30px;
		margin-bottom: 15px;
		cursor: pointer;
		cursor: pointer;
		border: 1px solid #dcdfe6;
		border-radius: 4px;
		background-color: #fff;
		.flexible(row, center, center);
		&:active {
			opacity: 0.8;
		}
		&.dropdown-button-shrink {
			transform: rotate(180deg);
		}
	}
}
.divider {
	width: 1px;
	height: 32px;
	background: #e5e5e5;
	margin-right: 15px;
	margin-bottom: 15px;
}
</style>

import axios from 'axios'
import { serialize } from '@/utils/util'
import { ElMessage } from 'element-plus'
import { router } from '@/router'
import { getStore } from '@/utils/store'
import { randomLenString } from '@/utils/util'
import website from '@/config/website'
import { refreshToken } from '@/api/user'
import { useUserStore } from '@/store/modules/user'
import { usePermissionStore } from '@/store/modules/permission'

function interceptor(http) {
	http.interceptors.request.use(
		async config => {
			const meta = config.meta || {}
			const isToken = meta.isToken === false
			config.headers['Request-Id'] = randomLenString(16)
			if (config?.params?.grant_type !== 'refresh_token') await tokenIsExpired()
			const token = getStore({ name: 'token' })
			// 让每个请求携带token
			if (token && !isToken) {
				config.headers[website.tokenHeader] = 'bearer ' + token
			}
			// headers中配置text请求
			if (config.text === true) {
				config.headers['Content-Type'] = 'text/plain'
			}
			// headers中配置serialize为true开启序列化
			if (config.method === 'post' && meta.isSerialize === true) {
				config.data = serialize(config.data)
			}
			return config
		},
		error => {
			return Promise.reject(error)
		},
	)
	http.interceptors.response.use(
		async res => {
			const userStore = useUserStore()
			// 获取状态码
			const status = res.status
			const message =
				res.data.msg || res.data.error_description || res.error_description || '未知错误'
			// 如果在白名单里则自行catch逻辑处理
			if (status === 401) {
				const requestUrl = res.config.url
				if (requestUrl.indexOf('oauth/token') != -1) {
					userStore.logout()
					router.push({ path: '/login' })
					return res
				}
				const refreshRes = await asyncRefreshToken()
				if (refreshRes) {
					const newToken = getStore({ name: 'token' })
					res.config.headers[website.tokenHeader] = 'bearer ' + newToken
					const newRes = await axios.request(res.config)
					if (newRes.status === 200 || newRes.status === '200') {
						return newRes.data
					} else {
						userStore.logout()
						router.push({ path: '/login' })
						throw new Error('登录失效, 请重新登录!')
					}
				} else {
					userStore.logout()
					router.push({ path: '/login' })
					throw new Error('登录失效, 请重新登录!')
				}
			}
			if (status === 404) {
				ElMessage.error('服务器开小差，请稍后重试')
				return
			}
			// 如果请求为非200否者默认统一处理
			if (status !== 200) {
				if (toString.call(res.data) === '[object Blob]') {
					return res.data.text().then(err => {
						try {
							const obj = JSON.parse(err)
							return Promise.reject(new Error(obj.msg))
						} catch (err) {
							return Promise.reject(new Error(err))
						}
					})
				} else {
					return Promise.reject(new Error(message))
				}
			}
			return res.data
		},
		error => {
			return Promise.reject(error)
		},
	)
}

let refreshTokenPromise = null
function asyncRefreshToken() {
	// 防止多次刷新Token
	if (refreshTokenPromise) {
		return refreshTokenPromise
	}

	refreshTokenPromise = new Promise(function (resolve) {
		const _refreshToken = getStore({ name: 'refreshToken' })
		const tenantId = getStore({ name: 'tenantId' })
		refreshToken(_refreshToken, tenantId)
			.then(responsData => {
				const userStore = useUserStore()
				if (responsData && responsData.access_token) {
					userStore.setToken(responsData.access_token)
					userStore.setRefreshToken(responsData.refresh_token)
					const expireTime = responsData.expires_in * 1000 + Date.now()
					userStore.setExpire(expireTime)
					userStore.setUserInfo(responsData)
					const roleIDList = responsData.roleId.split(',')
					if (!responsData.isAdmin) {
						const permissionStore = usePermissionStore()
						permissionStore.setAuthList(roleIDList)
					}
				} else {
					throw new Error('刷新Token失败!')
				}
				refreshTokenPromise = null
				resolve(true)
			})
			.catch(() => {
				refreshTokenPromise = null
				resolve(true)
			})
	})

	return refreshTokenPromise
}

export const getNewToken = async config => {
	try {
		const userStore = useUserStore()
		const _refreshToken = getStore({ name: 'refreshToken' })
		const tenantId = getStore({ name: 'tenantId' })
		const res = await refreshToken(_refreshToken, tenantId)
		if (res && res.access_token) {
			userStore.setToken(res.access_token)
			const expireTime = res.expires_in * 1000 + Date.now()
			userStore.setExpire(expireTime)
			if (!config) {
				ElMessage.success('token 刷新成功，正在重载页面')
				setTimeout(() => {
					window.location.reload()
				}, 1000)
			} else {
				// 主应用内部token过期，则自动重发上次请求，如果是微应用里过期，则提示用户重新操作
				config.headers[website.tokenHeader] = 'bearer ' + getStore({ name: 'token' })
				await axios.request(config)
			}
		} else {
			ElMessage.error('token 刷新失败，请重新登录')
			userStore.logout()
			router.push({ path: '/login' })
		}
	} catch (error) {
		console.error('getNewToken error =>', error)
	}
}

async function tokenIsExpired() {
	// 判断是否存在token，且当前时间 < token过期时间 - 10min
	const bufferTime = 600000
	const expire = getStore({ name: 'token-expire' })
	const currentTime = Date.now()
	if (expire) {
		if (currentTime >= expire - bufferTime) {
			const refreshRes = await asyncRefreshToken()
			return !refreshRes
		}
		return false
	} else {
		return false
	}
}

export default interceptor

<template>
	<div
		v-if="isExter"
		:style="styleExternalIcon"
		class="svg-external-icon svg-icon"
		v-bind="$attrs"
	/>
	<svg v-else :class="svgClass" aria-hidden="true" v-bind="$attrs">
		<use :xlink:href="iconName" />
	</svg>
</template>

<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
	name: 'SvgIcon',
	props: {
		iconClass: {
			type: String,
			required: true,
		},
		className: {
			type: String,
			default: '',
		},
	},
	setup(prop) {
		const isExternal = path => /^(https?:|mailto:|tel:)/.test(path)
		const isExter = computed(() => isExternal(prop.iconClass))
		const iconName = computed(() => `#icon-${prop.iconClass}`)
		const svgClass = computed(() => {
			if (prop.className) {
				return 'svg-icon ' + prop.className
			} else {
				return 'svg-icon'
			}
		})
		const styleExternalIcon = computed(() => {
			return {
				mask: `url(${prop.iconClass}) no-repeat 50% 50%`,
				'-webkit-mask': `url(${prop.iconClass}) no-repeat 50% 50%`,
			}
		})
		return {
			isExter,
			iconName,
			svgClass,
			styleExternalIcon,
		}
	},
})
</script>

<style lang="less" scoped>
.svg-icon {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}

.svg-external-icon {
	background-color: currentColor;
	mask-size: cover !important;
	display: inline-block;
}
</style>

import { computed } from 'vue'
import { useTagStore } from '@/store/modules/tag'

function getHumpStr(path) {
	if (!path) return ''
	const splitStr = path.split('/')
	return splitStr[splitStr.length - 1]
		.split('-')
		.map(s => s[0].toUpperCase() + s.slice(1))
		.join('')
}

export default function useTag(to) {
	const tagStore = useTagStore()
	const visitedViews = computed(() => {
		return tagStore.visitedViews
	})
	const index = visitedViews.value.findIndex(view => view.path === to.path)
	if (index > -1) return

	const tag = {
		title: to?.meta?.title || to.name,
		code: getHumpStr(to.path),
		path: to.path,
		isActive: true,
		noCache: false,
		affix: false,
		chartLayout: to.chartLayout || to?.meta?.chartLayout,
	}
	tagStore.addTag(tag)
}

<template>
	<a v-if="isExternal(to.path)" :href="to.path" target="_blank">
		<slot />
	</a>
	<div v-else @click="open">
		<slot />
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { isExternal } from '@/utils/validate'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store/modules/user'
import useTag from '@/hooks/useTag'
import md5 from 'js-md5'
export default defineComponent({
	props: {
		to: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const userStore = useUserStore()
		const router = useRouter()
		function open() {
			const { path, name } = props.to
			if (path.startsWith('erp2://') || path.startsWith('blank://')) {
				const pathSplit = path.split('://')
				const realPath = pathSplit[1]
				window.open(`http://${realPath}`, '_blank')
				return
			} else if (path.startsWith('bi://')) {
				const realPath = path.replace('bi://', '')
				goBiSystem(realPath)
				return
			} else if (isHttpURL(path)) {
				// example: http://nrp.test.itsmycar.cn/#/myiframe/urlPath?name=Sentinel管理&src=http://sentinel.prod.itsmycar.cn/
				window.open(
					`${process.env.VUE_APP_HOST}#/myiframe/urlPath?name=${name}&src=${path}`,
					'_blank',
				)
				return
			}
			useTag(props.to)
			router.push({
				path: props.to.path,
			})
		}
		function isHttpURL(s) {
			if (s.includes('html')) return true
			return /^http[s]?:\/\/.*/.test(s)
		}
		function goBiSystem(realPath) {
			const { emp_no, phone } = userStore.userInfo
			switch (true) {
				case realPath === 'report': {
					let iframeEl = document.createElement('iframe')
					const password = md5(`yiautos${emp_no}${phone.substring(phone.length - 4)}`)
					iframeEl.src = `https://report.yiautos.com/webroot/decision/login/cross/domain?fine_username=${emp_no}&fine_password=${password}&validity=-1&callback=callback`
					if (iframeEl.attachEvent) {
						//判断是否为ie浏览器
						iframeEl.attachEvent('onload', function () {
							window.location = window.open('https://report.yiautos.com/webroot/decision', '_blank')
						})
					} else {
						iframeEl.onload = function () {
							window.open('https://report.yiautos.com/webroot/decision', '_blank')
						}
					}
					document.getElementsByTagName('head')[0].appendChild(iframeEl)
					// 跳转完成后移除该iframe
					setTimeout(() => {
						document.getElementsByTagName('head')[0].removeChild(iframeEl)
					}, 10000)
					break
				}
				case realPath === 'fine_bi': {
					let iframeEl = document.createElement('iframe')
					const password = md5(`yiautos${emp_no}${phone.substring(phone.length - 4)}`)
					iframeEl.src = `https://bi.yiautos.com/webroot/decision/login/cross/domain?fine_username=${emp_no}&fine_password=${password}&validity=-1&callback=callback`
					if (iframeEl.attachEvent) {
						//判断是否为ie浏览器
						iframeEl.attachEvent('onload', function () {
							window.location = window.open('https://bi.yiautos.com/webroot/decision', '_blank')
						})
					} else {
						iframeEl.onload = function () {
							window.open('https://bi.yiautos.com/webroot/decision', '_blank')
						}
					}
					document.getElementsByTagName('head')[0].appendChild(iframeEl)
					// 跳转完成后移除该iframe
					setTimeout(() => {
						document.getElementsByTagName('head')[0].removeChild(iframeEl)
					}, 10000)
					break
				}
				case realPath.startsWith('http://'):
				case realPath.startsWith('https://'): {
					window.open(realPath.replace(/(.*)\/index/, '$1'), '_blank')
					break
				}
				default:
			}
		}
		return {
			open,
			isExternal,
		}
	},
})
</script>

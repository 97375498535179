import { defineStore } from 'pinia'
import { getStore, removeStore, setStore } from '@/utils/store'

export const useTagStore = defineStore({
	id: 'tag',
	state: () => ({
		visitedViews: getStore({ name: 'tags' }) || [],
		cachedViews: [],
	}),
	actions: {
		addTag(tag) {
			this.addVisitedTag(tag)
			this.addCachedTag(tag)
		},
		deleteTag(tag) {
			this.deleteVisitedTag(tag)
			this.deleteCachedTag(tag)
		},
		deleteOtherTags(tag) {
			this.deleteOtherVisitedTags(tag)
			this.deleteOtherCachedTags(tag)
		},
		addVisitedTag(view) {
			if (this.visitedViews.some(v => v.path === view.path)) return
			this.visitedViews.push(
				Object.assign({}, view, {
					title: view.title || 'no-name',
				}),
			)
			setStore({ name: 'tags', content: this.visitedViews })
		},
		addCachedTag(view) {
			if (this.cachedViews.includes(view.code)) return
			if (!view.noCache) {
				this.cachedViews.push(view.code)
				setStore({ name: 'cache-tags', content: this.cachedViews })
			}
		},
		deleteVisitedTag(view) {
			for (const [i, v] of this.visitedViews.entries()) {
				if (v.path === view.path) {
					this.visitedViews.splice(i, 1)
					break
				}
			}
			setStore({ name: 'tags', content: this.visitedViews })
		},
		deleteCachedTag(view) {
			const index = this.cachedViews.indexOf(view.code)
			index > -1 && this.cachedViews.splice(index, 1)
			setStore({ name: 'cache-tags', content: this.cachedViews })
		},
		deleteOtherVisitedTags(view) {
			this.visitedViews = this.visitedViews.filter(v => {
				return v.affix || v.path === view.path
			})
			setStore({ name: 'tags', content: this.visitedViews })
		},
		deleteOtherCachedTags(view) {
			const index = this.cachedViews.indexOf(view.code)
			if (index > -1) {
				this.cachedViews = this.cachedViews.slice(index, index + 1)
			} else {
				this.cachedViews = []
			}
			setStore({ name: 'cache-tags', content: this.cachedViews })
		},
		deleteAllVisitedTags() {
			// keep affix tags
			const affixTags = this.visitedViews.filter(tag => tag.affix)
			this.visitedViews = affixTags
			setStore({ name: 'tags', content: affixTags })
		},
		deleteAllTags() {
			this.visitedViews = []
			this.cachedViews = []
			removeStore({ name: 'tags' })
			removeStore({ name: 'cache-tags' })
		},
		deleteAllCachedTags() {
			this.cachedViews = []
			removeStore({ name: 'cache-tags' })
		},
		updateVisitedTag(view) {
			for (let v of this.visitedViews) {
				v.isActive = false
				if (v.path === view.path) {
					v = Object.assign(v, {
						...view,
						isActive: true,
					})
				}
			}
			setStore({ name: 'tags', content: this.visitedViews })
		},
	},
})

<template>
	<div class="hamburger" :class="[{ 'is-active': isActive }]" @click="toggleClick">
		<svg-icon icon-class="expand"></svg-icon>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { useAppStore } from '@/store/modules/app'
export default defineComponent({
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['toggle-click'],
	setup() {
		const appStore = useAppStore()
		const toggleClick = () => {
			appStore.toggleSidebar()
			// context.emit('toggle-click')
		}
		return {
			toggleClick,
		}
	},
})
</script>

<style lang="less" scoped>
.hamburger {
	cursor: pointer;
	color: var(--text-color);
	transition: color 0.25s;
	&.is-active {
		transform: rotate(180deg);
	}
}
</style>

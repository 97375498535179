import { defineStore } from 'pinia'
import { ElMessage } from 'element-plus'
import { setStore, getStore } from '@/utils/store'
import { getBizDictListByClientId } from '@/api/system/dict'

const enumList = getStore({ name: 'enums' }) || []
const enumsObj = {}

function dealDictList(enums) {
	enums.forEach(item => {
		const dictCode = item['code']
		const dictOptions = item['children']
		if (!dictCode || !dictOptions) {
			return
		}
		enumsObj[dictCode] = dictOptions.map(option => {
			return {
				code: option.dictKey,
				name: option.dictValue,
			}
		})
	})
}

dealDictList(enumList)

export const useDictStore = defineStore({
	id: 'dict',
	state: () => ({
		enums: {
			...enumsObj,
		},
		dicts: {},
	}),
	actions: {
		fetchAllEnums() {
			getBizDictListByClientId('fep_admin')
				.then(res => {
					res.data.map(item => {
						item.children = (item.children || []).filter(child => {
							return child.isSealed === 0
						})
					})

					dealDictList(res.data)
					this.enums = {
						...enumsObj,
					}

					setStore({ name: 'enums', content: res.data })
				})
				.catch(error => {
					ElMessage.error('枚举获取失败：', error.message)
				})
		},
		setDict(dicts) {
			this.dicts = dicts
		},
	},
})

import request from '@/http'
import md5 from 'js-md5'

// 发送短信验证码用于修改密码
export const sendSmsCodeMessage = function (mobile, tenantId) {
	return request({
		// url: `suf4-system-service/captcha/send/forgotPwd/${mobile}`,
		url: `suf4-user-service/captcha/send/forgotPwd/${mobile}`,
		method: 'get',
		params: {
			mobile,
		},
		headers: {
			'Tenant-Id': tenantId,
		},
	})
}

// 确认修改密码
export const confirmResetPwd = function (data, tenantId) {
	return request({
		url: 'suf4-user-service/userInfo/forgotPassword',
		method: 'post',
		// 此处不应用params明文传输, 应该为post
		data: {
			phone: data.account,
			captcha: data.msgCode,
			newPwd: md5(data.newPassword),
		},
		headers: {
			'Tenant-Id': tenantId,
		},
	})
}

export const loginByUsername = (tenantId, account, pwd) =>
	request({
		url: 'suf4-grant/oauth/token',
		method: 'post',
		headers: {
			'Tenant-Id': tenantId,
			Authorization: 'Basic YWRtaW46OG56QU1ITDlmQTNyY3FnRw==',
		},
		params: {
			grant_type: 'normal',
			account,
			pwd,
		},
	})

export const refreshToken = (refresh_token, tenantId) =>
	request({
		url: 'suf4-grant/oauth/token',
		method: 'post',
		headers: {
			'Tenant-Id': tenantId,
			Authorization: 'Basic YWRtaW46OG56QU1ITDlmQTNyY3FnRw==',
		},
		params: {
			refresh_token,
			grant_type: 'refresh_token',
			scope: 'all',
		},
	})

export const getUserInfo = () =>
	request({
		url: '/api/blade-auth/oauth/user-info',
		method: 'get',
	})

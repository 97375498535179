<template>
	<el-scrollbar
		ref="scrollContainerRef"
		class="scroll-container"
		:vertical="false"
		@wheel.prevent="handleScroll"
	>
		<slot />
	</el-scrollbar>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, computed, onMounted, onBeforeUnmount } from 'vue'

export default defineComponent({
	emits: ['scroll'],
	setup(props, context) {
		const scrollContainerRef = ref(null)

		const scrollWrapper = computed(() => {
			return scrollContainerRef.value.wrapRef
		})

		const tagSpacing = 4

		const state = reactive({
			handleScroll: event => {
				const eventDelta = event.wheelDelta || -event.deltaY * 40
				scrollWrapper.value.scrollLeft = scrollWrapper.value.scrollLeft + eventDelta / 4
			},
			moveToCurrentTag: currentTag => {
				const container = scrollContainerRef.value.$el
				const containerWidth = container.offsetWidth
				const tagList = scrollContainerRef.value.$parent.$parent.$refs.tag

				let firstTag = null
				let lastTag = null
				if (tagList.length > 0) {
					firstTag = tagList[0]
					lastTag = tagList[tagList.length - 1]
				}
				const currentTitle = currentTag.title.trim()
				if (firstTag.innerText.includes(currentTitle)) {
					scrollWrapper.value.scrollLeft = 0
				} else if (lastTag.innerText.includes(currentTitle)) {
					scrollWrapper.value.scrollLeft = scrollWrapper.value.scrollWidth - containerWidth
				} else {
					const currentIndex = tagList.findIndex(item => item.innerText.includes(currentTitle))
					const prevTag = tagList[currentIndex - 1]
					const nextTag = tagList[currentIndex + 1]
					const afterNextTagOffsetLeft = nextTag.offsetLeft + nextTag.offsetWidth + tagSpacing
					const beforePrevTagOffsetLeft = prevTag.offsetLeft - tagSpacing

					if (afterNextTagOffsetLeft > scrollWrapper.value.scrollLeft + containerWidth) {
						scrollWrapper.value.scrollLeft = afterNextTagOffsetLeft - containerWidth
					} else if (beforePrevTagOffsetLeft < scrollWrapper.value.scrollLeft) {
						scrollWrapper.value.scrollLeft = beforePrevTagOffsetLeft
					}
				}
			},
		})

		const emitScroll = () => {
			context.emit('scroll')
		}

		onMounted(() => {
			scrollWrapper.value.addEventListener('scroll', emitScroll, true)
		})

		onBeforeUnmount(() => {
			scrollWrapper.value.removeEventListener('scroll', emitScroll)
		})

		return {
			...toRefs(state),
			scrollContainerRef,
		}
	},
})
</script>

<style lang="less" scoped>
.scroll-container {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	position: relative;
	.yi-scrollbar__bar {
		bottom: 0px;
	}
	.yi-scrollbar__wrap {
		height: 49px;
	}
}
</style>

const driverRoutes = [
	{
		path: '/driver-register',
		component: () => import('@/views/driver/management/register'),
		name: 'DriverRegister',
		meta: {
			name: 'DriverRegister',
			title: '注册司机',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/driver-center/driver-register-manage',
		},
	},
	{
		path: '/driver-management',
		component: () => import('@/views/driver/management/signing'),
		name: 'DriverSigning',
		meta: {
			name: 'DriverSigning',
			title: '签约司机',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/driver-center/driver-manage',
		},
	},
	{
		path: '/driver-contract',
		component: () => import('@/views/driver/contract'),
		name: 'DriverContract',
		meta: {
			name: 'DriverContract',
			title: '合同管理',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/driver-center/contract-manage',
		},
	},
	{
		path: '/driver-rent',
		component: () => import('@/views/driver/rent'),
		name: 'DriverRent',
		meta: {
			name: 'DriverRent',
			title: '账单列表',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/driver-center/rent-bill',
		},
	},
	{
		path: '/reserve-car',
		component: () => import('@/views/driver/reserve'),
		name: 'ReserveCar',
		meta: {
			name: 'ReserveCar',
			title: '预约还车',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/driver-center/return-car',
		},
	},
	{
		path: '/settle-car',
		component: () => import('@/views/driver/settle'),
		name: 'SettleCar',
		meta: {
			name: 'SettleCar',
			title: '退车结算',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/driver-center/order-settlement',
		},
	},
	{
		path: '/company-customer',
		component: () => import('@/views/driver/customer'),
		name: 'CompanyCustomer',
		meta: {
			name: 'CompanyCustomer',
			title: '公司客户',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/driver-center/company-customer',
		},
	},
	{
		path: '/renewal-list',
		component: () => import('@/views/driver/renewal'),
		name: 'RenewalList',
		meta: {
			name: 'RenewalList',
			title: '续签记录',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/driver-center/contract-renewal',
		},
	},
	{
		path: '/delay-list',
		component: () => import('@/views/driver/delay'),
		name: 'DelayList',
		meta: {
			name: 'DelayList',
			title: '合同延期',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/driver-center/order-delay',
		},
	},
]

export default driverRoutes

<template>
	<div class="yi-pagination-box">
		<div class="pagination-box-left">
			<span>
				共{{ totalCount }}条
				<span v-if="selectedCount > 0">, 已选{{ selectedCount }}条</span>
			</span>
		</div>
		<slot></slot>
		<el-pagination
			layout="prev, pager, next"
			:total="totalCount"
			:page-size="pageSize"
			:current-page="currentPage"
			background
			@current-change="onPageChange"
		/>
		<div class="pagination-box-attach pagination-box-right">
			<el-select
				class="page-size-select"
				:model-value="pageSize"
				placeholder="页码"
				@change="onPageSizeChange"
			>
				<el-option
					v-for="item in pageSizeOptionsComp"
					:key="item.code"
					:label="item.name"
					:value="item.code"
				/>
			</el-select>
		</div>
	</div>
</template>

<script>
import { defineComponent, computed, reactive, toRefs } from 'vue'

export default defineComponent({
	name: 'YiPagination',
	props: {
		totalCount: {
			type: Number,
			default: 0,
		},
		currentPage: {
			type: Number,
			default: 0,
		},
		pageSize: {
			type: Number,
			default: 20,
		},
		selectedCount: {
			type: Number,
			default: 0,
		},
		scrollEnable: {
			type: Boolean,
			default: false,
		},
		customSizeOptions: {
			type: Array,
			default: () => [],
		},
	},
	components: {},
	emits: ['page-change', 'size-change'],
	setup(props, context) {
		const state = reactive({
			pageSizeOptions: [
				{ code: 20, name: '20条/页' },
				{ code: 30, name: '30条/页' },
				{ code: 40, name: '40条/页' },
				{ code: 50, name: '50条/页' },
				{ code: 100, name: '100条/页' },
			],
		})

		state.pageSizeOptionsComp = computed(() => {
			if (props.customSizeOptions.length === 0) {
				return state.pageSizeOptions
			}
			return props.customSizeOptions
		})

		const methods = {
			onPageChange(page) {
				context.emit('page-change', page)
			},
			onPageSizeChange(size) {
				context.emit('size-change', size)
			},
		}

		return {
			...toRefs(state),
			...methods,
		}
	},
})
</script>

<style lang="less" scoped>
.yi-pagination-box {
	width: 100%;
	height: 54px;
	padding-top: 6px;
	box-sizing: border-box;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	.pagination-box-attach {
		width: 200px;
	}

	.pagination-box-left {
		font-family: PingFangSC-Semibold;
		font-size: 14px;
		color: #2e2e2e;
	}

	.pagination-box-right {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
	}

	.page-size-select {
		width: 100px;
	}
}
</style>

export const chartsRoutes = [
	{
		path: '/chart-cockpit',
		component: () => import('@/views/chart/cockpit/index'),
		name: 'Cockpit',
		meta: {
			name: 'Cockpit',
			title: '驾驶舱仪表盘',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			chartLayout: true,
		},
	},
	// {
	// 	path: '/chart-cockpit',
	// 	component: () => import('@/views/chart/cockpit/index'),
	// 	name: 'Cockpit',
	// 	meta: {
	// 		name: 'Cockpit',
	// 		title: '驾驶舱仪表盘old',
	// 		icon: '#icon_role',
	// 		affix: false,
	// 		keepAlive: true,
	// 		chartLayout: true,
	// 	},
	// },
	{
		path: '/chart-real',
		component: () => import('@/views/chart/realtime-data'),
		name: 'RealtimeData',
		meta: {
			name: 'RealtimeData',
			title: '实时数据',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			chartLayout: true,
		},
	},
	{
		path: '/chart-operate',
		component: () => import('@/views/chart/operate-data'),
		name: 'OperateData',
		meta: {
			name: 'OperateData',
			title: '运营数据',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			chartLayout: true,
		},
	},
]

export const reportRoutes = [
	{
		path: '/chart-driver-partner',
		component: () => import('@/views/chart/driver-partner'),
		name: 'DriverPartner',
		meta: {
			name: 'DriverPartner',
			title: '司服伙伴报表',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
		},
	},
	{
		path: '/chart-maintainer',
		component: () => import('@/views/chart/maintainer'),
		name: 'ChartMaintainer',
		meta: {
			name: 'ChartMaintainer',
			title: '维保专员报表',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
		},
	},
	{
		path: '/chart-entry',
		component: () => import('@/views/chart/entry'),
		name: 'ChartEntry',
		meta: {
			name: 'DriverPartner',
			title: '入库员报表',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
		},
	},
	{
		path: '/chart-outbound',
		component: () => import('@/views/chart/outbound'),
		name: 'ChartOutbound',
		meta: {
			name: 'DriverPartner',
			title: '出库员报表',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
		},
	},
]

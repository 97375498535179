/**
 * 全局配置文件
 */
export default {
	title: process.env.VUE_APP_TITLE,
	key: process.env.VUE_APP_KEY, // 配置主键,目前用于存储
	clientId: 'fep_admin', // 客户端id
	clientSecret: 'CiAb4DsaenGZPbgE', // 客户端密钥
	tokenHeader: 'Suf4-Auth',
}

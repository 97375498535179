import { defineStore } from 'pinia'
import { topMenu, routeMenu } from '@/config/menu'
import { useUserStore } from '@/store/modules/user'
import { getStore, setStore, removeStore } from '@/utils/store'
import Request from '@/http'
export const usePermissionStore = defineStore({
	id: 'permission',
	state: () => ({
		menus: getStore({ name: 'menus' }) || [], // 顶部子应用菜单
		menuId: getStore({ name: 'menuId' }) || {}, // 当前激活的菜单
		routes: getStore({ name: 'routes' }) || [], // 侧边栏路由
		authList: getStore({ name: 'auth-list' }) || [], // 用户菜单权限
	}),
	actions: {
		setMenus() {
			let topMenuList = []
			topMenuList = [...topMenu]
			const userStore = useUserStore()
			const { isAdmin } = userStore.userInfo
			this.menus = topMenuList.filter(menuItem => {
				if (!menuItem.auth || isAdmin) return true
				const authIndex = this.authList.findIndex(authItem => {
					return authItem.code === menuItem.auth
				})
				return authIndex !== -1
			})
			setStore({ name: 'menus', content: this.menus })
			this.setMenuId(this.menus[0])
			return Promise.resolve(this.menus)
		},
		setAuthList(roleIdList) {
			return Request({
				url: 'suf4-user-service/roleMenuRel/listByRoleIdList',
				method: 'POST',
				data: { roleIdList },
			})
				.then(res => {
					if (res.code === 200) {
						this.authList = res.data
						setStore({ name: 'auth-list', content: res.data })
						return this.authList
					} else {
						throw new Error(res.msg)
					}
				})
				.catch(error => {
					console.log('获取权限列表失败 ==>', error)
				})
		},
		clearMenus() {
			this.menus = []
			removeStore({ name: 'menus' })
		},
		setMenuId(menu) {
			this.menuId = menu
			setStore({ name: 'menuId', content: menu })
		},
		clearMenuId() {
			this.menuId = {}
			removeStore({ name: 'menuId' })
		},
		setRoutes(menuId) {
			let code = ''
			switch (menuId) {
				case '2':
					code = 'risk'
					break
				case '3':
					code = 'system'
					break
				case '4':
					code = 'chart'
					break
				case '8':
					code = 'vehicle'
					break
				case '7':
					code = 'product'
					break
				case '9':
					code = 'driver'
					break
				case '10':
					code = 'finance'
					break
				case '11':
					code = 'recruit'
					break
				default:
					code = 'dashboard'
			}
			const data = routeMenu[code]

			// const userInfo = JSON.parse(window.localStorage.getItem(website.key + '-userInfo')).content
			let routesMenuList = []
			routesMenuList = [...data]
			const userStore = useUserStore()
			const { isAdmin } = userStore.userInfo
			this.routes = routesMenuList.filter(routeItem => {
				if (!routeItem.auth || isAdmin) return true
				if (routeItem.children) {
					routeItem.children = routeItem.children.map(childItem => {
						if (!childItem.auth || isAdmin) return true
						const authIndex = this.authList.findIndex(authItem => {
							return authItem.code === routeItem.auth
						})
						return authIndex !== -1
					})
					if (routeItem.children.length === 0) {
						return false
					}
				}
				const authIndex = this.authList.findIndex(authItem => {
					return authItem.code === routeItem.auth
				})
				return authIndex !== -1
			})

			setStore({ name: 'routes', content: this.routes })
			return this.routes
		},
		clearRoutes() {
			this.routes = []
			removeStore({ name: 'routes' })
		},
	},
})

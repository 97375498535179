import { defineStore } from 'pinia'
import { getStore, setStore, removeStore } from '@/utils/store'
import { setToken, removeToken, setRefreshToken, removeRefreshToken } from '@/utils/cookies'
import { usePermissionStore } from './permission'
import { useAppStore } from './app'
import { useTagStore } from './tag'

export const useUserStore = defineStore({
	id: 'user',
	state: () => ({
		token: getStore({ name: 'token' }) || '',
		tokenExpire: getStore({ name: 'token-expire' }) || '',
		refreshToken: getStore({ name: 'refreshToken' }) || '',
		userInfo: getStore({ name: 'userInfo' }) || {},
		permission: getStore({ name: 'permission' }) || {},
		showIM: getStore({ name: 'permission' })?.chat_tim || false,
	}),
	actions: {
		setToken(token) {
			this.token = token
			setToken(token)
			setStore({ name: 'token', content: token })
		},
		clearToken() {
			this.token = ''
			removeToken()
			removeStore({ name: 'token' })
		},
		setExpire(expireTime) {
			this.tokenExpire = expireTime
			setStore({ name: 'token-expire', content: expireTime })
		},
		clearExpire() {
			this.tokenExpire = ''
			removeStore({ name: 'token-expire' })
		},
		setRefreshToken(refreshToken) {
			this.refreshToken = refreshToken
			setRefreshToken(refreshToken)
			setStore({ name: 'refreshToken', content: refreshToken })
		},
		clearRefreshToken() {
			this.refreshToken = ''
			removeRefreshToken()
			removeStore({ name: 'refreshToken' })
		},
		setUserInfo(userInfo) {
			this.userInfo = userInfo
			setStore({
				name: 'userInfo',
				content: userInfo,
			})
		},
		clearUserInfo() {
			this.userInfo = {}
			removeStore({ name: 'userInfo' })
		},
		logout() {
			this.clearToken()
			this.clearExpire()
			this.clearRefreshToken()
			this.clearUserInfo()

			const permissionStore = usePermissionStore()
			permissionStore.clearMenus()
			permissionStore.clearRoutes()
			permissionStore.clearMenuId()

			const tagStore = useTagStore()
			tagStore.deleteAllTags()

			const appStore = useAppStore()
			appStore.clearLock()
		},
	},
})

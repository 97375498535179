import request from '@/http'

export const getSysDictList = params => {
	return request({
		url: '/api/blade-system/dict/dictionary',
		method: 'GET',
		params,
	})
}

export const getBizDictList = (current, size, params) => {
	return request({
		url: '/api/blade-system/dict-biz/list',
		method: 'GET',
		params: {
			...params,
			current,
			size,
		},
	})
}

export const getBizDictListByClientId = clientId => {
	return request({
		url: '/api/blade-system/dict-biz/listByClientId',
		method: 'GET',
		params: {
			clientId,
		},
	})
}

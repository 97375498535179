<template>
	<div class="yi-table-box">
		<el-table
			ref="tableRef"
			v-bind="paramsComp"
			:height="tableHeight"
			:max-height="tableMaxHeight"
			:data="data"
			:row-key="rowKey"
			:highlight-current-row="highlightCurrentRow"
			@selection-change="onSelectionChange"
			@cell-click="onCellClick"
			@sort-change="onSortChange"
		>
			<el-table-column
				v-if="showSelection"
				width="50"
				align="center"
				header-align="center"
				type="selection"
				:reserve-selection="reserveSelection"
				fixed
			/>
			<el-table-column v-if="showNo" width="80">
				<template #header>{{ noName }}</template>
				<template #default="scope">
					{{ (params.page.current - 1) * params.page.size + scope.$index + 1 }}
				</template>
			</el-table-column>
			<template v-for="column of columns" :key="column.prop">
				<el-table-column
					v-if="column.type === 'link'"
					v-bind="column"
					:align="column.align"
					:width="column.width || defaultTableWidthComp"
					:formatter="!column.formatter ? emptyFormatter : column.formatter"
					:show-overflow-tooltip="column.showTooltip === false ? false : true"
				>
					<template #default="{ row, $index }">
						<el-button type="primary" link @click="onLinkClick(column.prop, row)">
							{{ row[column.prop] }}
						</el-button>
					</template>
				</el-table-column>
				<el-table-column
					v-else
					v-bind="column"
					:align="column.align"
					:width="column.width || defaultTableWidthComp"
					:formatter="!column.formatter ? emptyFormatter : column.formatter"
					:show-overflow-tooltip="column.showTooltip === false ? false : true"
				>
					<template #default="{ row, $index }" v-if="$slots[column.prop]">
						<slot :name="column.prop" :value="row[column.prop]" :row="row" :index="$index"></slot>
					</template>
					<template #header v-if="$slots[column.prop + '-header']">
						<slot :name="column.prop + '-header'"></slot>
					</template>
				</el-table-column>
			</template>
			<slot name="action"></slot>
		</el-table>
	</div>
</template>

<script>
import { defineComponent, reactive, computed, toRefs, getCurrentInstance } from 'vue'

export default defineComponent({
	name: 'YiTable',
	props: {
		tableHeight: {
			type: [String, Number],
		},
		tableMaxHeight: {
			type: [String, Number],
			default: '900',
		},
		rowKey: {
			type: String,
			default: 'id',
		},
		reserveSelection: {
			type: Boolean,
			default: false,
		},
		// 开启序号，必须在params传入分页信息
		showNo: {
			type: Boolean,
			default: false,
		},
		noName: {
			type: String,
			default: '序号',
		},
		showSelection: {
			type: Boolean,
			default: false,
		},
		params: {
			type: Object,
			default: () => ({}),
		},
		columns: {
			type: Array,
			default: () => [],
		},
		data: {
			type: Array,
			default: () => [],
		},
		highlightCurrentRow: {
			type: Boolean,
			default: false,
		},
		autoWidth: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	emits: ['selection-change', 'cell-click', 'link-click', 'sort-change'],
	setup(props, context) {
		const instance = getCurrentInstance()

		const state = reactive({
			selectable: true,
			paramsComp: {},
			defaultTableWidthComp: '',
		})

		state.paramsComp = computed(() => {
			return Object.assign(
				{},
				{
					size: 'default',
					border: true,
				},
				props.params,
			)
		})
		const bodyWidth = document.body.clientWidth
		state.defaultTableWidthComp = computed(() => {
			const defaultColumnWidth = parseInt((bodyWidth - 310) / props.columns.length)
			if (props.autoWidth) {
				return null
			}
			return defaultColumnWidth < 200 ? 200 : defaultColumnWidth
		})

		const methods = {
			emptyFormatter(row, column, value) {
				if (!value && value !== 0) {
					return '--'
				}
				return value
			},
			onSelectionChange(event) {
				context.emit('selection-change', event)
			},
			onCellClick(row, column, cell) {
				context.emit('cell-click', row, column, cell)
			},
			onSortChange(row) {
				context.emit('sort-change', row)
			},
			onLinkClick(attr, row) {
				context.emit('link-click', attr, row)
			},
			clearSelection() {
				instance.refs.tableRef.clearSelection()
			},
			setCurrent(row) {
				instance.refs.tableRef.setCurrentRow(row)
			},
			scrollTop() {
				instance.refs.tableRef.setScrollTop(0)
			},
			doLayout() {
				instance.refs.tableRef.doLayout()
			},
		}

		return {
			...toRefs(state),
			...methods,
		}
	},
})
</script>

<style lang="less" scoped>
.yi-table-box {
	width: 100%;
	height: 100%;
	overflow: hidden;
	:deep(.yi-table--scrollable-y .yi-table__body-wrapper) {
		overflow-y: auto;
	}
}
</style>

import ElementPlus from 'element-plus'
import * as ElIcons from '@element-plus/icons-vue'
import SvgIcon from '@/components/svg-icon/Index'
import YiListLayout from '@/components/YiListLayout/Index'
import YiFilter from '@/components/YiFilter/Index'
import YiTable from '@/components/YiTable/Index'
import YiPagination from '@/components/YiPagination/Index'
import YiFileUploader from '@/components/YiFileUploader/Index'
import YiForm from '@/components/YiForm'

const req = require.context('@/assets/svg', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)

export function registerGlobComp(app) {
	for (let iconName in ElIcons) {
		app.component(iconName, ElIcons[iconName])
	}

	app.component('SvgIcon', SvgIcon)
	app.component('YiListLayout', YiListLayout)
	app.component('YiFilter', YiFilter)
	app.component('YiTable', YiTable)
	app.component('YiPagination', YiPagination)
	app.component('YiFileUploader', YiFileUploader)
	app.component('YiForm', YiForm)

	app.use(ElementPlus)
}

import axios from 'axios'
import { baseUrl } from '@/config/env'
import interceptor from '@/http/interceptor'

const http = axios.create({
	baseURL: baseUrl,
	timeout: 20000,
	validateStatus: status => status >= 200 && status <= 500,
})

interceptor(http)

export default http

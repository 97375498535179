const vehicleRoutes = [
	{
		path: '/vehicle-management',
		component: () => import('@/views/vehicle/management'),
		name: 'VehicleManagement',
		meta: {
			name: 'VehicleManagement',
			title: '车辆管理',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/car-center/car-manage',
		},
	},
	{
		path: '/vehicle-check',
		component: () => import('@/views/vehicle/check'),
		name: 'VehicleCheck',
		meta: {
			name: 'VehicleCheck',
			title: '入库验车',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/car-center/in-store',
		},
	},
	{
		path: '/vehicle-delivery',
		component: () => import('@/views/vehicle/delivery'),
		name: 'VehicleDelivery',
		meta: {
			name: 'VehicleDelivery',
			title: '出库交车',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/car-center/out-store',
		},
	},
	{
		path: '/maintenance-management',
		component: () => import('@/views/vehicle/maintenance'),
		name: 'MaintenanceManagement',
		meta: {
			name: 'MaintenanceManagement',
			title: '维保管理',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/car-center/mainten-manage',
		},
	},
	{
		path: '/violation-summary',
		component: () => import('@/views/vehicle/violation'),
		name: 'ViolationSummary',
		meta: {
			name: 'ViolationSummary',
			title: '违章汇总',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/car-center/Illegal-count',
		},
	},
	{
		path: '/violation-management',
		component: () => import('@/views/vehicle/violationDetail'),
		name: 'ViolationManagement',
		meta: {
			name: 'ViolationManagement',
			title: '违章记录',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/car-center/Illegal-record',
		},
	},
	{
		path: '/incident-management',
		component: () => import('@/views/vehicle/incident'),
		name: 'IncidentManagement',
		meta: {
			name: 'IncidentManagement',
			title: '事故管理',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/car-center/accident-manage',
		},
	},
	{
		path: '/annual-inspection',
		component: () => import('@/views/vehicle/annualInspection'),
		name: 'AnnualInspection',
		meta: {
			name: 'AnnualInspection',
			title: '年检记录',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/car-center/annual-audit',
		},
	},
	{
		path: '/insurance-records',
		component: () => import('@/views/vehicle/insurance'),
		name: 'InsuranceRecords',
		meta: {
			name: 'InsuranceRecords',
			title: '保险记录',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/car-center/car-insurance',
		},
	},
]

export default vehicleRoutes

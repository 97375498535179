import { ref, inject } from 'vue'
import Request from '@/http'

function useState(initial) {
	const state = ref(initial)
	const dispatch = next => {
		const draft = typeof next === 'function' ? next(state.value) : next
		state.value = draft
	}
	return [state, dispatch]
}

function useFetch() {
	const [isLoading, setLoading] = useState(false)
	const [data, setData] = useState(null)
	const [err, setErr] = useState(null)
	async function fetchData(service, ...params) {
		try {
			setLoading(true)
			const res = await service(...params)
			if (res.code === 200) {
				setData(res.data)
				setErr(null)
			} else {
				setErr(res.msg || res.message)
			}
		} catch (err) {
			setErr(err)
		} finally {
			setLoading(false)
			// eslint-disable-next-line no-unsafe-finally
			return { data: data.value, err: err.value }
		}
	}
	return { isLoading, fetchData }
}

function useModal() {
	const [visible, setVisible] = useState(false)
	const [title, setTitle] = useState('')
	return { visible, title, setVisible, setTitle }
}

export async function useDict(key) {
	try {
		const res = await Request({
			url: 'suf4-system-service/dictBiz/listByCode',
			method: 'POST',
			data: Array.isArray(key) ? key : [key],
		})
		if (res.code !== 200) {
			throw new Error(res.message || res.msg)
		}
		return res.data
	} catch (e) {
		console.log('获取字典错误', e)
	}
}

class InitTable {
	constructor(service, params = {}) {
		this.service = service
		const { page } = params || {}
		if (!page) params.page = { current: 1, size: 20 }
		this.params = params
		this.totalCount = ref(0)
		this.tableData = ref([])
		this.isLoading = ref(false)
		this.$message = inject('$message')
		// this.fetchList()
		this.res = {
			...this.onPageChange(),
			service: this.service,
			params: this.params,
			totalCount: this.totalCount,
			tableData: this.tableData,
			isLoading: this.isLoading,
		}
	}
	async fetchList({ onPrev = () => {}, onSuccess = () => {}, onFailed = () => {} } = {}) {
		try {
			this.isLoading.value = true
			onPrev && onPrev(this)
			const res = await this.service(this.params)
			if (res.code === 200) {
				const { records, total } = res.data
				this.tableData.value = records
				this.totalCount.value = total
				onSuccess && onSuccess(this, res)
			} else {
				const e = res.msg || res.message
				onFailed && onFailed(this, res)
				this.$message.error(e)
			}
		} catch (e) {
			onFailed && onFailed(this, e)
			this.$message.error(e.message)
		} finally {
			this.isLoading.value = false
		}
	}
	onPageChange() {
		const onPageChange = val => {
			this.params.page.current = val
			this.fetchList()
		}
		const onSizeChange = val => {
			this.params.page.size = val
			this.params.page.current = 1
			this.fetchList()
		}
		return { page: this.params.page, onPageChange, onSizeChange }
	}
	onChangeFilter(params) {
		this.params = { ...params, page: this.params.page || { current: 1, page: 20 } }
		this.fetchList()
	}
}

async function useSelect(service, options = {}) {
	const { err, data } = await service()
	if (err) return { err }
	const { name = 'name', code = 'id' } = options
	const select = data.map(item => ({
		...item,
		value: item[code],
		label: item[name],
	}))
	return { data: select, all: data }
}
async function useFilter(service, options = {}) {
	const { err, data } = await service()
	if (err) return { err }
	const { name = 'name', code = 'id' } = options
	const filter = data.map(item => ({
		code: item[code],
		name: item[name],
	}))
	return { data: filter }
}

export { useState, useFetch, InitTable, useModal, useFilter, useSelect }

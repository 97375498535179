const recruitRoutes = [
	{
		path: '/apply-driver',
		component: () => import('@/views/recruit/apply'),
		name: 'ApplyDriver',
		meta: {
			name: 'ApplyDriver',
			title: '报名司机',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/recruitment-center/driver-clue/page',
		},
	},
	{
		path: '/banner-setting',
		component: () => import('@/views/recruit/banner'),
		name: 'BannerSetting',
		meta: {
			name: 'BannerSetting',
			title: 'Banner设置',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/recruitment-center/banner',
		},
	},
]

export default recruitRoutes

<template>
	<div class="switch-theme" @click="handleClick">
		<svg-icon :icon-class="themeClass"></svg-icon>
	</div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import { useAppStore } from '@/store/modules/app'
export default defineComponent({
	setup() {
		const appStore = useAppStore()
		const themeClass = ref('sun')
		const theme = computed(() => appStore.theme)
		const handleClick = () => {
			if (theme.value === '') {
				document.documentElement.classList.add('dark')
				appStore.toggleTheme('dark')
				return
			}
			if (theme.value === 'light') {
				toggleTheme('light', 'dark')
				themeClass.value = 'moon'
				return
			}
			toggleTheme('dark', 'light')
			themeClass.value = 'sun'
		}
		onMounted(() => {
			document.documentElement.classList.add(theme.value)
			themeClass.value = theme.value === 'dark' ? 'moon' : 'sun'
		})
		function toggleTheme(o, n) {
			document.documentElement.classList.replace(o, n)
			appStore.toggleTheme(n)
		}
		return {
			handleClick,
			themeClass,
		}
	},
})
</script>

<style lang="less" scoped>
.switch-theme {
	color: var(--text-color);
	cursor: pointer;
	transition: color 0.25s;
	.svg-icon {
		font-size: 20px;
	}
}
</style>

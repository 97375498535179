import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout/index.vue'
import whitelist from './modules/whitelist'
import SystemRoutes from './modules/system'
import VehicleRoutes from './modules/vehicle'
import RiskRoutes from './modules/risk'
import DriverRoutes from './modules/driver'
import productRoutes from './modules/product'
import { chartsRoutes, reportRoutes } from './modules/chart'
import financeRoutes from './modules/finance'
import recruitRoutes from './modules/recruit'

export const constantRoutes = [
	{
		path: '/',
		component: Layout,
		redirect: '/dashboard',
		children: [
			{
				path: 'dashboard',
				component: () => import('@/views/dashboard'),
				name: 'Dashboard',
				meta: {
					name: 'Dashboard',
					title: '工作台',
					icon: '#icon_dashboard',
					affix: true,
				},
			},
			...SystemRoutes,
			...VehicleRoutes,
			...RiskRoutes,
			...DriverRoutes,
			...reportRoutes,
			...chartsRoutes,
			...financeRoutes,
			...productRoutes,
			...recruitRoutes,
		],
	},
	...whitelist,
]

export const router = createRouter({
	history: createWebHistory(process.env.VUE_APP_SUBPATH),
	routes: constantRoutes,
})
export function setupRouter(app) {
	app.use(router)
}

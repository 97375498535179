<template>
	<div class="has-logo">
		<SidebarLogo :is-collapse="isCollapse" />
		<el-scrollbar wrap-class="scrollbar-wrapper">
			<el-menu
				ref="elMenuRef"
				mode="vertical"
				:collapse="!isCollapse"
				:unique-opened="true"
				:default-active="activeMenu"
				@open="handleOpen"
			>
				<SidebarItem
					v-for="route in routes"
					:key="route.id"
					:item="route"
					:base-path="route.path"
				/>
			</el-menu>
		</el-scrollbar>
		<div class="footer-version">构建时间：{{ buildTime }}</div>
	</div>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import SidebarItem from './SidebarItem.vue'
import SidebarLogo from './SidebarLogo.vue'
import { usePermissionStore } from '@/store/modules/permission'
import { useAppStore } from '@/store/modules/app'
import { useRoute } from 'vue-router'

export default defineComponent({
	components: {
		SidebarItem,
		SidebarLogo,
	},
	setup() {
		const appStore = useAppStore()
		const permissionStore = usePermissionStore()
		const route = useRoute()
		const sidebar = computed(() => {
			return appStore.sidebar
		})
		const routes = computed(() => {
			return permissionStore.routes
		})
		let openIds = []
		const elMenuRef = ref(null)

		const activeMenu = computed(() => {
			const { meta, path } = route

			if (meta !== null || meta !== undefined) {
				if (meta.activeMenu) {
					return meta.activeMenu
				}
			}
			return path
		})
		const isCollapse = computed(() => {
			return appStore.sidebar.opened
		})

		watch(
			() => permissionStore.routes,
			() => {
				if (openIds.length > 0) {
					elMenuRef.value.close(openIds[0])
				}
			},
		)
		const handleOpen = (key, keyPath) => {
			openIds = keyPath
		}

		const buildTime = ref(process.env.VUE_APP_UPDATE_TIME)

		return {
			handleOpen,
			buildTime,
			elMenuRef,
			sidebar,
			routes,
			activeMenu,
			isCollapse,
		}
	},
})
</script>

<style lang="less">
.sidebar-container {
	.scrollbar-wrapper {
		overflow-x: hidden !important;
	}

	.el-scrollbar__view {
		height: 100%;
	}

	.el-scrollbar__bar {
		&.is-vertical {
			right: 0px;
		}

		&.is-horizontal {
			display: none;
		}
	}
}
.el-menu--collapse .el-menu-item > span,
.el-menu--collapse .el-sub-menu > .el-sub-menu__title > span {
	height: 0;
	width: 0;
	overflow: hidden;
	visibility: hidden;
	display: inline-block;
}
.el-menu--collapse .el-menu-item .el-sub-menu__icon-arrow,
.el-menu--collapse .el-sub-menu > .el-sub-menu__title .el-sub-menu__icon-arrow {
	display: none;
}
</style>

<style lang="less" scoped>
.el-scrollbar {
	height: 100%;
}

.has-logo {
	.el-scrollbar {
		height: calc(100% - 60px);
	}
}

.el-menu {
	border: none;
	height: 100%;
	width: 100% !important;
	background-color: var(--bg-color);
	transition: none;
}

.footer-version {
	width: 100%;
	color: #ccc;
	font-size: 14px;
	text-align: center;
	display: none;
	position: absolute;
	bottom: 10px;
	left: 0;
}
</style>

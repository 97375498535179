<template>
	<div class="top-menu">
		<div class="top-menu-bar">
			<div class="top-menu-list">
				<template v-for="(item, index) in menuList">
					<span
						v-if="index < size"
						:key="index"
						class="top-menu-item"
						:class="{
							'top-menu-item-active': index === activeIndex,
						}"
						@click="openMenu(item)"
					>
						{{ item.name }}
					</span>
				</template>
				<el-popover
					v-if="size < menuList.length"
					popper-class="more-menu"
					placement="bottom"
					:width="402"
					trigger="hover"
				>
					<template #reference>
						<span
							class="top-menu-item"
							:class="{
								'top-menu-item-active': activeIndex >= size,
							}"
						>
							<svg-icon icon-class="menu" style="margin-right: 5px"></svg-icon>
							更多功能
						</span>
					</template>
					<div class="more-menu-con">
						<template v-for="(item, index) in menuList">
							<span
								v-if="index >= size"
								:key="index"
								class="more-menu-item"
								:class="{
									active: index === activeIndex,
								}"
								@click="openMenu(item)"
							>
								{{ item.name }}
							</span>
						</template>
					</div>
				</el-popover>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs, onMounted, onBeforeUnmount } from 'vue'
import { usePermissionStore } from '@/store/modules/permission'
import { storeToRefs } from 'pinia'
import { debounce } from '@/utils/util'

export default {
	setup() {
		const permissionStore = usePermissionStore()
		const state = reactive({
			activeIndex: 0,
			menuList: [],
			clientWidth: 0,
			size: 1, // 在显示的菜单数量，超出的放到下拉面板里显示
		})
		const methods = reactive({
			openMenu(menu) {
				const index = state.menuList.findIndex(item => item.id === menu.id)
				if (index === state.activeIndex) return
				state.activeIndex = index
				permissionStore.setRoutes(menu.id)
				permissionStore.setMenuId(menu)
			},
		})
		const bindEvent = debounce(
			function () {
				setSize()
			},
			1000,
			false,
		)
		function setSize() {
			const clientWidth = document.body.clientWidth
			// 780 = 220(.sidebar-container) + 50(.hamburger) + 100(更多功能按钮的宽度) + 100(.top-menu-bar到右侧.right-menu 的间距) + 270(.right-menu)
			const surplusWidth = clientWidth - 740
			const menuItemSpaceWidth = 30
			const wordWidth = 14
			const menuNameList = state.menuList.map(item => item.name.length)
			let count = state.size
			while (count > 0 || count < menuNameList.length) {
				if (
					menuItemSpaceWidth * count + getWordsCount(menuNameList, count) * wordWidth <
					surplusWidth
				) {
					count++
				} else {
					count--
					if (
						menuItemSpaceWidth * count + getWordsCount(menuNameList, count) * wordWidth >
						surplusWidth
					) {
						count--
					} else {
						break
					}
				}
			}
			state.size = count
		}
		function getWordsCount(menuNameList, count) {
			return menuNameList.reduce((acc, cur, index) => {
				if (index < count) {
					acc += cur
				}
				return acc
			}, 0)
		}
		onMounted(() => {
			const { menus, menuId } = storeToRefs(permissionStore)
			const index = menus.value.findIndex(menu => menu.id === menuId.value.id)
			state.menuList = menus.value
			state.activeIndex = index
			setSize()
			window.addEventListener('resize', bindEvent, false)
		})
		onBeforeUnmount(() => {
			window.removeEventListener('resize', bindEvent, false)
		})
		return {
			...toRefs(state),
			...toRefs(methods),
		}
	},
}
</script>
<style lang="less">
.el-popper.more-menu {
	width: 402px;
	padding: 0 !important;
	.more-menu-con {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		padding: 20px 0 10px 20px;
	}
	.more-menu-item {
		width: 110px;
		text-align: center;
		border-radius: 4px;
		line-height: 34px;
		line-height: 34px;
		margin: 0 15px 15px 0;
		cursor: pointer;
		&.active,
		&:hover {
			background-color: rgba(64, 158, 255, 0.1);
			color: @primary;
		}
	}
}
</style>
<style lang="less" scoped>
.top-menu {
	height: 100%;
	padding-left: 10px;
	.flexible(row, center, flex-start);
	.top-menu-bar {
		height: 34px;
		.flexible(row, center, flex-start);
		overflow: hidden;
		position: relative;
		.top-menu-list {
			display: flex;
			overflow: hidden;
			.top-menu-item {
				margin-right: 10px;
				border-radius: 17px;
				height: 34px;
				font-size: 14px;
				padding: 0 10px;
				white-space: nowrap;
				color: var(--text-color);
				transition: color 0.25s, background-color 0.5s;
				font-weight: 600;
				.clickable;
				.flexible(row, center, center);
				&:hover {
					background-color: var(--bg-menu-hover);
				}
				&.top-menu-item-active {
					background-color: var(--bg-menu-active);
					color: var(--text-active-color);
				}
			}
		}
	}
}
</style>

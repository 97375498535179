let baseUrl = ''
const env = process.env

const urlList = {
	dev: '//api-dev.renrenjia.com.cn/',
	test: '//api-test.renrenjia.com.cn/',
	pre: '//api-test.renrenjia.com.cn/',
	prod: '//api-prod.renrenjia.com.cn/',
}
baseUrl = urlList[env.VUE_APP_CONFIG_ENV]
let protocol = 'https:'
if (window.location.protocol.includes('s')) {
	protocol = 'https:'
} else if (env.VUE_APP_CONFIG_ENV === 'dev') {
	protocol = 'http:'
}
baseUrl = `${protocol}${baseUrl}`

export { baseUrl }

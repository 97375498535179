<template>
	<div class="screenfull" id="screenfull" @click="handleClick">
		<svg-icon :icon-class="isFullscreen ? 'fullscreen-exit' : 'fullscreen'"></svg-icon>
	</div>
</template>

<script>
import screenfull from 'screenfull'
import { defineComponent, onBeforeUnmount, onMounted, reactive, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
// import { ElMessage } from '@liulijian/element-plus'

const sf = screenfull
export default defineComponent({
	setup() {
		const state = reactive({
			isFullscreen: false,
			handleClick: () => {
				if (!sf.isEnabled) {
					ElMessage({
						message: '你的浏览器不支持',
						type: 'warning',
					})
					return false
				}
				sf.toggle()
			},
		})
		const change = () => {
			if (sf.isEnabled) {
				state.isFullscreen = sf.isFullscreen
			}
		}
		onMounted(() => {
			if (sf.isEnabled) {
				sf.on('change', change)
			}
		})

		onBeforeUnmount(() => {
			if (sf.isEnabled) {
				sf.off('change', change)
			}
		})

		return {
			...toRefs(state),
		}
	},
})
</script>
<style lang="less" scoped>
.screenfull {
	cursor: pointer;
	color: var(--text-color);
	transition: color 0.25s;
	.svg-icon {
		font-size: 22px;
	}
}
</style>

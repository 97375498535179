<template>
	<div id="tags-view-container" class="tags-view-container">
		<ScrollPane ref="scrollPaneRef" class="tags-view-wrapper">
			<div
				v-for="tag in visitedViews"
				ref="tag"
				:key="tag.path"
				:class="tag.isActive ? 'active' : ''"
				:to="{ path: tag.path }"
				tag="span"
				class="tags-view-item"
				@click="tagClick(tag)"
			>
				{{ tag.title }}
				<el-icon v-if="!isAffix(tag)" @click.prevent.stop="closeSelectedTag(tag)">
					<close />
				</el-icon>
			</div>
		</ScrollPane>
		<el-dropdown>
			<div class="more-action">
				<svg-icon icon-class="more"></svg-icon>
			</div>
			<template #dropdown>
				<el-dropdown-menu>
					<el-dropdown-item @click="closeOthersTags">关闭其他</el-dropdown-item>
					<el-dropdown-item @click="closeAllTags">关闭所有</el-dropdown-item>
				</el-dropdown-menu>
			</template>
		</el-dropdown>
	</div>
</template>

<script>
import { computed, defineComponent, nextTick, reactive, ref, toRefs, watch, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useTagStore } from '@/store/modules/tag'
import { getStore } from '@/utils/store'
import whitelist from '@/router/modules/whitelist'
import ScrollPane from './ScrollPane'

export default defineComponent({
	components: {
		ScrollPane,
	},
	setup() {
		const tagStore = useTagStore()
		const router = useRouter()
		const route = useRoute()
		const scrollPaneRef = ref(null)
		let currentPath = '/dashboard'

		const visitedViews = computed(() => {
			return tagStore.visitedViews
		})

		const initTags = () => {
			// 当刷新浏览器的时候会清空缓存的tag，所以需要将当前命中的 tag 加入到缓存中
			const activeTag = (getStore({ name: 'tags' }) || []).filter(tag => tag.isActive)
			if (activeTag.length === 0) return
			tagStore.addCachedTag(activeTag[0])
		}

		const moveToCurrentTag = async () => {
			// 需要等待 tagView 渲染完成后
			await nextTick()
			for (const tag of visitedViews.value) {
				if (currentPath === tag.path) {
					scrollPaneRef.value?.moveToCurrentTag(tag)
					tagStore.updateVisitedTag(tag)
					tagStore.addCachedTag(tag)
				}
			}
		}

		const toLastView = visitedViews => {
			const latestView = visitedViews.slice(-1)[0]
			if (!latestView) {
				router.push({
					path: state.redirect || '/',
					query: state.otherQuery,
				})
				tagStore.addTag({
					title: '工作台',
					code: 'dashboard',
					path: '/dashboard',
					isActive: true,
					noCache: false,
					affix: true,
				})
			} else {
				const path = latestView.path || '/'
				router.push(path)
			}
		}

		const state = reactive({
			visible: false,
			top: 0,
			left: 0,
			selectedTag: {},
			affixTags: [],
			isActive: route => {
				return currentPath.includes(route.path)
			},
			isAffix: tag => {
				return tag.affix
			},
			closeSelectedTag: view => {
				tagStore.deleteTag(view)
				if (state.isActive(view)) {
					toLastView(visitedViews.value, view)
				}
			},
			closeOthersTags: () => {
				if (!currentPath.includes(state.selectedTag.path) && state.selectedTag.path !== undefined) {
					router.push(state.selectedTag.path)
				}
				const activeTag = visitedViews.value.filter(tag => tag.isActive)[0]
				tagStore.deleteOtherTags(activeTag)
			},
			closeAllTags: view => {
				tagStore.deleteAllVisitedTags()
				tagStore.deleteAllCachedTags()
				if (state.affixTags.some(tag => currentPath.includes(tag.path))) {
					return
				}
				toLastView(visitedViews.value, view)
			},
		})
		const tagClick = tag => {
			const currPath = window.location.href
			if (currPath.includes(tag.path)) return
			console.log('tagClick', tag, currPath)
			// 当刷新浏览器的时候会清空缓存的tag，所以再次点击 tag 的时候，需要加入到缓存中
			tagStore.addCachedTag(tag)
			const path = tag.path ?? '/'
			router.push(path)
		}

		watch(
			() => route.path,
			n => {
				currentPath = n
				const paths = whitelist.map(route => route.path)
				if (paths.includes(n) || route.name === '404') return
				moveToCurrentTag()
			},
			{ immediate: true },
		)

		onMounted(() => {
			initTags()
		})

		return {
			visitedViews,
			scrollPaneRef,
			tagClick,
			...toRefs(state),
		}
	},
})
</script>

<style lang="less" scoped>
.tags-view-container {
	display: flex;
	height: 50px;
	width: 100%;
	background: #fff;
	box-shadow: 0 4px 4px 0 rgba(51, 51, 51, 0.04);

	.tags-view-wrapper {
		.tags-view-item {
			display: inline-block;
			position: relative;
			cursor: pointer;
			height: 38px;
			line-height: 38px;
			color: #495060;
			background: #fff;
			padding: 0 10px;
			font-size: 12px;
			margin: 0 5px;
			margin-top: 12px;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			&:first-of-type {
				margin-left: 15px;
			}
			&:last-of-type {
				margin-right: 15px;
			}
			&.active {
				background-color: rgba(64, 158, 255, 0.1);
				color: @primary;
				font-weight: 600;
				&::before {
					position: absolute;
					left: -6px;
					bottom: 0;
					content: '';
					width: 0;
					height: 0;
					border-bottom: 6px solid #ecf5ff;
					border-left: 6px solid transparent;
				}
				&::after {
					position: absolute;
					right: -6px;
					bottom: 0;
					content: '';
					width: 0;
					height: 0;
					border-left: 6px solid #ecf5ff;
					border-top: 6px solid transparent;
				}
			}
			.el-icon {
				vertical-align: -2px;
				visibility: hidden;
				transition: all 0.25s;
			}
			&:hover {
				.el-icon {
					visibility: visible;
				}
			}
		}
		.active {
			.el-icon {
				visibility: visible;
			}
		}
		.el-icon:hover {
			transform: scale(1.4);
		}
	}
	.el-dropdown {
		width: 50px;
		margin: 4px 10px 0;
		.el-button--small {
			height: 26px;
			line-height: 26px;
		}
	}
}
.more-action {
	padding: 10px;
	cursor: pointer;
	position: absolute;
	right: 0;
	bottom: 5px;
	.svg-icon {
		font-size: 20px;
	}
}
</style>

import website from './website'

export const Keys = {
	tokenKey: website.key + '-access-token',
	refreshTokenKey: website.key + '-refresh-token',
	sidebarStatusKey: 'vue3-typescript-admin-sidebarStatusKey',
	languageKey: 'vue3-typescript-admin-languageKey',
	sizeKey: 'vue3-typescript-admin-sizeKey',
	aseKey: 'vue3-typescript-admin-ase-key',
}

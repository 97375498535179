import { createApp } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
// import 'virtual:svg-icons-register'
import { setupStore } from '@/store'
import { setupRouter, router } from '@/router'
import { registerGlobComp } from '@/components/registerGlobComp'
import { getNameFromEnums } from '@/utils/util'
import { permissionDirective } from '@/directives/permission'
import App from './App.vue'

import '@/router/interceptor'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import '@/styles/index.less'

// import './mock'

async function bootstrap() {
	const app = createApp(App)

	// 配置 store
	setupStore(app)

	// 注册全局组件
	registerGlobComp(app)

	// 配置路由
	setupRouter(app)

	// https://next.router.vuejs.org/api/#isready
	await router.isReady()

	// 注册全局属性
	app.config.globalProperties.getNameFromEnums = getNameFromEnums

	// 注册全局变量
	app.provide('$message', ElMessage)
	app.provide('$messageBox', ElMessageBox)

	// 注册自定义指令
	app.directive('permission', permissionDirective)

	app.mount('#app', true)
}

bootstrap()

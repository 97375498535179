<template>
	<div :class="classObj" class="app-wrapper">
		<Sidebar class="sidebar-container" />
		<div class="main-container">
			<div>
				<Navbar />
				<TagsView />
			</div>
			<div class="app-main">
				<div class="am-layout" :class="{ 'is-chart-layout': isChartLayout }">
					<router-view v-slot="{ Component }">
						<keep-alive :include="cachedViews">
							<component :is="Component" />
						</keep-alive>
					</router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, defineComponent, onMounted } from 'vue'
import { useAppStore } from '@/store/modules/app'
import { useTagStore } from '@/store/modules/tag'
import { Navbar, TagsView, Sidebar } from './components'
import { removeStore } from '@/utils/store'

export default defineComponent({
	name: 'Layout',
	components: {
		Navbar,
		Sidebar,
		TagsView,
	},
	setup() {
		const appStore = useAppStore()
		const tagStore = useTagStore()
		const cachedViews = computed(() => {
			return tagStore.cachedViews
		})
		const sidebar = computed(() => {
			return appStore.sidebar
		})
		const classObj = computed(() => {
			return {
				hideSidebar: !sidebar.value.opened,
				openSidebar: sidebar.value.opened,
				withoutAnimation: sidebar.value.withoutAnimation,
			}
		})
		const isChartLayout = computed(() => {
			const activeTag = tagStore.visitedViews.filter(tag => tag.isActive)
			return activeTag[0]?.chartLayout
		})

		onMounted(() => {
			// 页面刷新的时候需要清空缓存的 cache-tags
			window.addEventListener('beforeunload', () => {
				removeStore({ name: 'cache-tags' })
			})
		})
		return {
			classObj,
			sidebar,
			cachedViews,
			isChartLayout,
		}
	},
})
</script>
<style lang="less">
.am-layout-container {
	height: 100%;
	& > div {
		height: 100%;
	}
}
.app-main {
	.el-loading-parent--relative {
		position: relative !important;
	}
	.el-loading-mask {
		position: absolute;
		z-index: 2000;
		background-color: rgba(255, 255, 255, 0.9);
		margin: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: opacity var(--el-transition-duration);
	}
	.el-loading-spinner {
		top: 400px;
		margin-top: calc((0px - var(--el-loading-spinner-size)) / 2);
		width: 100%;
		text-align: center;
		position: absolute;
	}
	.el-loading-spinner .circular {
		display: inline;
		height: var(--el-loading-spinner-size);
		width: var(--el-loading-spinner-size);
		animation: loading-rotate 2s linear infinite;
	}
	.el-loading-spinner .path {
		animation: loading-dash 1.5s ease-in-out infinite;
		stroke-dasharray: 90, 150;
		stroke-dashoffset: 0;
		stroke-width: 2;
		stroke: var(--el-color-primary);
		stroke-linecap: round;
	}
}
</style>
<style lang="less" scoped>
.app-wrapper {
	.clearfix;
	position: relative;
	height: 100%;
	width: 100%;
}
.app-main {
	width: 100%;
	height: calc(100% - 110px);
	overflow-y: auto;
	overflow-x: hidden;
	box-sizing: border-box;
	padding: 10px;
	background-color: #f0f2f5;
}
.am-layout {
	position: relative;
	background-color: white;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 16px;
	overflow: hidden;
}
.is-chart-layout {
	background-color: #f0f2f5;
	height: initial;
	overflow: initial;
	padding: 0;
}

.main-container {
	height: 100%;
	min-height: 100%;
	transition: margin-left 0.25s;
	margin-left: @sideBarWidth;
	position: relative;
}

.sidebar-container {
	width: @sideBarWidth !important;
	height: 100%;
	position: fixed;
	font-size: 0px;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	overflow: hidden;
	background-color: var(--bg-color);
	transition: background-color 0.5s, width 0.25s;
}

.fixed-header {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 9;
	width: calc(100% - @sideBarWidth);
	transition: width 0.28s;
}

.hideSidebar {
	.main-container {
		margin-left: 64px;
	}

	.sidebar-container {
		width: 64px !important;
	}

	.fixed-header {
		width: calc(100% - 64px);
	}
}
</style>

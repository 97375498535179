const riskRoutes = [
	{
		path: 'gps',
		component: () => import('@/views/risk/gps/index'),
		name: 'GPS',
		meta: {
			name: 'GPS',
			title: 'GPS记录',
			icon: '#icon_account',
			affix: false,
			keepAlive: true,
			auth: 'pc/risk-center/gps-record',
		},
	},
	{
		path: 'qianzu',
		component: () => import('@/views/risk/qianzu/index'),
		name: 'Qianzu',
		meta: {
			name: 'Qianzu',
			title: '欠租时长',
			icon: '#icon_account',
			affix: false,
			keepAlive: true,
			auth: 'pc/risk-center/rent-arrears-time',
		},
	},
	{
		path: 'daituiche',
		component: () => import('@/views/risk/daituiche/index'),
		name: 'DaiTuiChe',
		meta: {
			name: 'DaiTuiChe',
			title: '待退车预警',
			icon: '#icon_account',
			affix: false,
			keepAlive: true,
			auth: 'pc/risk-center/return-car-warning',
		},
	},
	{
		path: 'weizhangyujing',
		component: () => import('@/views/risk/weizhangyujing/index'),
		name: 'WeiZhangYuJing',
		meta: {
			name: 'WeiZhangYuJing',
			title: '违章预警',
			icon: '#icon_account',
			affix: false,
			keepAlive: true,
			auth: 'pc/risk-center/Illegal-warning',
		},
	},
	{
		path: 'shiguyujing',
		component: () => import('@/views/risk/shiguyujing/index'),
		name: 'ShiGuYuJing',
		meta: {
			name: 'ShiGuYuJing',
			title: '事故预警',
			icon: '#icon_account',
			affix: false,
			keepAlive: true,
			auth: 'pc/risk-center/accident-warning',
		},
	},
	{
		path: 'baoyangyujing',
		component: () => import('@/views/risk/baoyangyujing/index'),
		name: 'BaoYangYuJing',
		meta: {
			name: 'BaoYangYuJing',
			title: '保养预警',
			icon: '#icon_account',
			affix: false,
			keepAlive: true,
			auth: 'pc/risk-center/maintain-warning',
		},
	},
	{
		path: 'overTime',
		component: () => import('@/views/risk/overTime/index'),
		name: 'overTime',
		meta: {
			name: 'overTime',
			title: '超时维修预警',
			icon: '#icon_account',
			affix: false,
			keepAlive: true,
			auth: 'pc/risk-center/overtime-repairs-warning',
		},
	},
	{
		path: 'yunYingRed',
		component: () => import('@/views/risk/yunyingred/index'),
		name: 'yunYingRed',
		meta: {
			name: 'yunYingRed',
			title: '车辆运营红线',
			icon: '#icon_account',
			affix: false,
			keepAlive: true,
			auth: 'pc/risk-center/overtime-repairs-warning',
			// auth: 'pc/risk-center/warning-line',
		},
	},
	{
		path: 'yyRedRules',
		component: () => import('@/views/risk/yyredrules/index'),
		name: 'yyRedRules',
		meta: {
			name: 'yyRedRules',
			title: '运营红线规则',
			icon: '#icon_account',
			affix: false,
			keepAlive: true,
			auth: 'pc/risk-center/overtime-repairs-warning',
			// auth: 'pc/risk-center/warning-line-rule',
		},
	},
]

export default riskRoutes

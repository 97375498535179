const systemRoutes = [
	{
		path: 'account',
		component: () => import('@/views/system/account/index'),
		name: 'Account',
		meta: {
			name: 'Account',
			title: '账号管理',
			icon: '#icon_account',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/account-warning',
		},
	},
	{
		path: 'role',
		component: () => import('@/views/system/role/index'),
		name: 'Role',
		meta: {
			name: 'Role',
			title: '角色管理',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/role-warning',
		},
	},
	{
		path: 'models',
		component: () => import('@/views/system/models/index'),
		name: 'Models',
		meta: {
			name: 'Models',
			title: '车型管理',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/car-model-warning',
		},
	},
	{
		path: 'platform-channel',
		component: () => import('@/views/system/platform-channel/index'),
		name: 'PlatformChannel',
		meta: {
			name: 'PlatformChannel',
			title: '平台渠道',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/platform-warning',
		},
	},
	{
		path: 'maintenance-shop',
		component: () => import('@/views/system/maintenance-shop/index'),
		name: 'MaintenanceShop',
		meta: {
			name: 'MaintenanceShop',
			title: '维修厂',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/maintain-factory',
		},
	},
	{
		path: 'car-store',
		component: () => import('@/views/system/car-store/index'),
		name: 'CarStore',
		meta: {
			name: 'CarStore',
			title: '车辆存放',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/car-store',
		},
	},
	{
		path: 'order-type',
		component: () => import('@/views/system/order-type/index'),
		name: 'OrderType',
		meta: {
			name: 'OrderType',
			title: '订单类型',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/order-source',
		},
	},
	{
		path: 'maintenance-type',
		component: () => import('@/views/system/maintenance-type/index'),
		name: 'MaintenanceType',
		meta: {
			name: 'MaintenanceType',
			title: '维修项目',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/repair-project',
		},
	},
	{
		path: 'insurance-company',
		component: () => import('@/views/system/insurance-company/index'),
		name: 'InsuranceCompany',
		meta: {
			name: 'InsuranceCompany',
			title: '保险公司',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/insurance-company',
		},
	},
	{
		path: 'enterprise-list',
		component: () => import('@/views/system/enterprise-list/index'),
		name: 'EnterpriseList',
		meta: {
			name: 'EnterpriseList',
			title: '子公司维护',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/comany-manage',
		},
	},
	{
		path: 'sms-record',
		component: () => import('@/views/system/sms-record/index'),
		name: 'SmsRecord',
		meta: {
			name: 'SmsRecord',
			title: '短信记录',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/sms-record',
		},
	},
	{
		path: 'wxpublic-record',
		component: () => import('@/views/system/wxpublic-record/index'),
		name: 'WxpublicRecord',
		meta: {
			name: 'WxpublicRecord',
			title: '公众号记录',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/mp-record',
		},
	},
	{
		path: 'dingtalk-record',
		component: () => import('@/views/system/dingtalk-record/index'),
		name: 'DingtalkRecord',
		meta: {
			name: 'DingtalkRecord',
			title: '钉钉记录',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/dintalk-record',
		},
	},
	{
		path: 'profile',
		component: () => import('@/views/system/profile/index'),
		name: 'Profile',
		meta: {
			name: 'Profile',
			title: '修改密码',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
		},
	},
	{
		path: 'data-auth',
		component: () => import('@/views/system/data-auth/index'),
		name: 'DataAuth',
		meta: {
			name: 'DataAuth',
			title: '数据权限',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/data-permission',
		},
	},
	{
		path: 'user-manage',
		component: () => import('@/views/system/user-manage/index'),
		name: 'UserManage',
		meta: {
			name: 'UserManage',
			title: '用户管理',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/config-center/biz-user',
		},
	},
]

export default systemRoutes

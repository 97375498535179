<template>
	<el-config-provider :locale="locale">
		<router-view />
	</el-config-provider>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'

export default defineComponent({
	components: {
		ElConfigProvider,
	},
	setup() {
		onMounted(() => {})

		return {
			locale: zhCn,
		}
	},
})
</script>

<style lang="less">
html,
body {
	height: 100%;
	margin: 0;
}
#app {
	height: 100%;
}
</style>

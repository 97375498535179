import { defineStore } from 'pinia'
import { getSidebarStatus, setSidebarStatus } from '@/utils/cookies'
import { getStore, setStore, removeStore } from '@/utils/store'

export const useAppStore = defineStore({
	id: 'app',
	state: () => ({
		theme: getStore({ name: 'theme' }) || 'light',
		tenantId: getStore({ name: 'tenantId' }) || '100000',
		sidebar: {
			opened: getSidebarStatus() !== 'closed',
			withoutAnimation: false,
		},
		imVisible: false,
		lockPasswd: getStore({ name: 'lockPasswd' }) || '',
	}),
	actions: {
		setTenantId(tenantId) {
			this.tenantId = tenantId
			setStore({ name: 'tenantId', content: tenantId })
		},
		toggleSidebar(withoutAnimation) {
			this.sidebar.opened = !this.sidebar.opened
			this.sidebar.withoutAnimation = withoutAnimation
			if (this.sidebar.opened) {
				setSidebarStatus('opened')
			} else {
				setSidebarStatus('closed')
			}
		},
		toggleTheme(theme) {
			this.theme = theme
			setStore({ name: 'theme', content: theme })
		},
		closeSidebar(withoutAnimation) {
			this.sidebar.opened = false
			this.sidebar.withoutAnimation = withoutAnimation
			setSidebarStatus('closed')
		},
		toggleDevice(device) {
			this.device = device
		},
		setLock(lockPasswd) {
			this.lockPasswd = lockPasswd
			setStore({
				name: 'lockPasswd',
				content: lockPasswd,
				type: 'session',
			})
		},
		clearLock() {
			this.lockPasswd = ''
			removeStore({
				name: 'lockPasswd',
				type: 'session',
			})
		},
	},
})

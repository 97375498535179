export const getFileTypeDefaultImage = (url, name) => {
	const fileType = name && name.split('.').pop().toLocaleUpperCase()
	const imageType = [
		'BMP',
		'JPG',
		'JPEG',
		'PNG',
		'TIF',
		'GIF',
		'PCX',
		'TGA',
		'EXIF',
		'FPX',
		'SVG',
		'PSD',
		'CDR',
		'PCD',
		'DXF',
		'UFO',
		'EPS',
		'AI',
		'RAW',
		'WMF',
		'WEBP',
		'AVIF',
		'APNG',
		'ICO',
		'ICON',
	]
	const musicType = ['MIDI', 'WMA', 'VQF', 'AMR', 'OGG', 'MP1', 'MP2', 'MP3', 'APE', 'FLAC']
	const videoType = [
		'AVI',
		'RM',
		'RMVB',
		'FLV',
		'MPG',
		'MPEG',
		'MPE',
		'MOV',
		'WMV',
		'ASF',
		'ASX',
		'3PG',
		'M4V',
		'DAT',
		'MKV',
		'VOB',
	]
	if (imageType.includes(fileType)) {
		return url
	}
	if (musicType.includes(fileType)) {
		return 'https://websitedn.yiautos.com/ymc_icon/uploader/music.png'
	}
	if (videoType.includes(fileType)) {
		return 'https://websitedn.yiautos.com/ymc_icon/uploader/video.png'
	}
	if ('ZIP' === fileType) {
		return 'https://websitedn.yiautos.com/ymc_icon/uploader/zip.png'
	}
	if ('RAR' === fileType) {
		return 'https://websitedn.yiautos.com/ymc_icon/uploader/rar.png'
	}
	if (['PPT', 'PPTX'].includes(fileType)) {
		return 'https://websitedn.yiautos.com/ymc_icon/uploader/ppt.png'
	}
	if (['DOC', 'DOCX'].includes(fileType)) {
		return 'https://websitedn.yiautos.com/ymc_icon/uploader/word.png'
	}
	if (['XLS', 'XLSX'].includes(fileType)) {
		return 'https://websitedn.yiautos.com/ymc_icon/uploader/execl.png'
	}
	if (['PDF'].includes(fileType)) {
		return 'https://websitedn.yiautos.com/ymc_icon/uploader/pdf.png'
	}

	return 'https://websitedn.yiautos.com/ymc_icon/uploader/file.png'
}

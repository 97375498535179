const productRoutes = [
	{
		path: '/product-management',
		component: () => import('@/views/product/management'),
		name: 'ProductManagement',
		meta: {
			name: 'ProductManagement',
			title: '商品管理',
			icon: '#icon_role',
			affix: false,
			keepAlive: true,
			auth: 'pc/goods/lease/paginate',
		},
	},
]

export default productRoutes

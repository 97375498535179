<template>
	<div class="yi-file-upload-box">
		<div
			v-for="(url, index) in modelValue"
			class="uploaded-image-box"
			:key="url"
			:style="{ marginBottom: isFileName ? '30px' : '20px', 'line-height': '20px' }"
		>
			<el-image
				v-if="type === 'image'"
				class="image-content"
				fit="cover"
				:src="url"
				:preview-src-list="
					allImages.length > 0 ? [...allImages] : singlePreview ? [url] : [...modelValue]
				"
				:initial-index="index"
				:hide-on-click-modal="hideOnClickModal"
			/>
			<div v-else class="file-content" @click="downloadFile(url)">
				<el-tooltip class="item" effect="dark" placement="bottom" :content="fileNameFilter(url)">
					<div class="file-info">
						<img class="image-content" :src="fileDefaultImageSrc(url, url)" />
						<span v-if="isFileName" class="file-name">{{ fileNameFilter(url) }}</span>
					</div>
				</el-tooltip>
			</div>
			<div v-if="!disabled" class="uploaded-delete-button" @click="onDelete(url)">
				<el-icon><close /></el-icon>
			</div>
		</div>
		<div
			v-if="canUploadComp && !disabled"
			class="uploader-button"
			:class="!disabled ? 'can-click' : ''"
			@click="onPick"
		>
			<div class="uploader-button-icon-vertical"></div>
			<div class="uploader-button-icon-horizontal"></div>
		</div>
		<uploader-dialog
			ref="uploaderDialogRef"
			:max="remainCountComp"
			:type="type"
			:product-type="productType"
			:source-type="sourceType"
			:is-real="isReal"
			:width-ratio="widthRatio"
			:height-ratio="heightRatio"
			:width-size="widthSize"
			:height-size="heightSize"
			:img-format="imgFormat"
			:slot-content="slotContent"
			:uploader-size="uploaderSize"
			:accept="accept"
			@change="onUploadChange"
		/>
	</div>
</template>

<script>
import { defineComponent, inject, reactive, toRefs, ref, computed } from 'vue'
import { getFileTypeDefaultImage } from './utils'
import UploaderDialog from './Dialog'

export default defineComponent({
	name: 'YiFileUploader',
	components: {
		UploaderDialog,
	},
	emits: ['update:modelValue'],
	props: {
		modelValue: {
			type: [Array, String],
			default() {
				return []
			},
		},
		type: {
			type: String,
			default: 'image', // image file
		},
		max: {
			type: Number,
			default: 9,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		hideOnClickModal: {
			type: Boolean,
			default: true,
		},
		widthRatio: {
			type: Number,
		},
		heightRatio: {
			type: Number,
		},
		imgFormat: {
			type: Array,
		},
		widthSize: {
			type: Number,
		},
		heightSize: {
			type: Number,
		},
		slotContent: {
			type: String,
		},
		uploaderSize: {
			type: Number,
		},
		/*
		 ** 产品线类型
		 ** 具体取值参照文档
		 ** https://alidocs.dingtalk.com/i/team/l2Amonngxo6A8Xdb/docs/l2Amo51WMKP18zdb
		 */
		productType: {
			type: String,
			required: true,
			default: 'cyd',
		},
		/*
		 ** 资源类型
		 ** 具体取值参照文档
		 ** https://alidocs.dingtalk.com/i/team/l2AmoqZonaa8Ezdb/docs/l2Amo5yWod8MVzdb
		 */
		sourceType: {
			type: String,
			required: true,
			default: 'static',
		},
		// 是否实名
		isReal: {
			type: Boolean,
			default: false,
		},
		// 是否显示文件名，type为file有效
		isFileName: {
			type: Boolean,
			default: false,
		},
		// 指定上传类型(.pdf,.png,.jpg,.jpeg)
		// 该选项也会限制上传的文件类型
		accept: {
			type: String,
			default: '*',
		},
		// 单张预览模式
		singlePreview: {
			type: Boolean,
			default: false,
		},
		// 全页面图片预览
		// 如果需要预览页面所有的图片，请将页面的所有图片都放入此字段
		allImages: {
			type: Array,
			default: () => [],
		},
	},
	setup(props, context) {
		const uploaderDialogRef = ref(null)

		const $message = inject('$message')

		const state = reactive({})

		state.canUploadComp = computed(() => {
			if (!props.modelValue) return 0 < props.max
			return props.modelValue.length < props.max
		})

		state.remainCountComp = computed(() => {
			if (!props.modelValue) return props.max - 0
			return props.max - props.modelValue.length
		})

		const methods = {
			fileNameFilter(file) {
				try {
					const fileSuffixArr = file.split('/')
					const fileName = fileSuffixArr[fileSuffixArr.length - 1].replace(/&/g, '')
					return fileName
				} catch (e) {
					return '暂无文件名信息!'
				}
			},
			onPick() {
				if (props.disabled) return
				if (!props.productType || !props.sourceType) return $message.error('必填项参数未配置')
				uploaderDialogRef.value.open(props.type)
			},
			onDelete(url) {
				const urls = [...props.modelValue]
				const deleteIndex = urls.findIndex(item => {
					return item === url
				})
				if (deleteIndex !== -1) {
					urls.splice(deleteIndex, 1)
					context.emit('update:modelValue', urls)
				}
			},
			onUploadChange(list) {
				context.emit('update:modelValue', [...(props.modelValue || []), ...list])
			},
			downloadFile(url) {
				window.open(url, '_blank')
			},
			fileDefaultImageSrc(url, name) {
				return getFileTypeDefaultImage(url, name)
			},
		}

		return {
			...toRefs(state),
			...methods,
			uploaderDialogRef,
		}
	},
})
</script>

<style lang="less" scoped>
.yi-file-upload-box {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	.uploaded-image-box {
		width: 120px;
		height: 120px;
		border-radius: 4px;
		margin-right: 20px;
		margin-bottom: 20px;
		position: relative;
		.image-content {
			width: 120px;
			height: 120px;
			border-radius: 4px;
		}
		.file-content {
			width: 120px;
			height: 120px;
			border-radius: 4px;
			cursor: pointer;
			&:active {
				opacity: 0.8;
			}
			.file-info {
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: center;
				.file-name {
					display: inline-block;
					width: 116px;
					padding-top: 4px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 12px;
					color: #999;
				}
			}
		}
		.uploaded-delete-button {
			position: absolute;
			right: -5px;
			top: -5px;
			width: 26px;
			height: 26px;
			background-color: #f56c6c;
			border-radius: 13px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			font-family: PingFangSC-Semibold;
			font-size: 14px;
			color: #fff;
			cursor: pointer;
			&:active {
				opacity: 0.8;
			}
		}
	}
	.uploader-button {
		width: 120px;
		height: 120px;
		border-radius: 4px;
		margin-bottom: 20px;
		border: 1px dashed #e5e5e5;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-family: PingFangSC-Semibold;
		font-size: 14px;
		color: #fff;
		position: relative;
		&.can-click {
			cursor: pointer;
			&:active {
				opacity: 0.8;
			}
		}
		.uploader-button-icon-vertical {
			width: 4px;
			height: 40px;
			border-radius: 2px;
			margin: auto;
			background-color: #e5e5e5;
			position: absolute;
		}
		.uploader-button-icon-horizontal {
			width: 40px;
			height: 4px;
			border-radius: 2px;
			margin: auto;
			background-color: #e5e5e5;
			position: absolute;
		}
	}
}
</style>

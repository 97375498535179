<template>
	<el-dialog
		v-model="isShowUploader"
		top="5vh"
		width="960px"
		class="yi-file-upload-dialog"
		:title="type === 'image' ? '图片上传' : '文件上传'"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		append-to-body
		modal-append-to-body
	>
		<div class="uploader-content-box" v-loading="isLoading">
			<el-upload
				class="upload-body"
				:limit="maxComp"
				:auto-upload="false"
				:show-file-list="false"
				:on-change="onImagePick"
				:on-exceed="onExceed"
				:file-list="fileList"
				:accept="accept"
				drag
				multiple
			>
				<div class="upload-drag-area">
					<img
						class="upload-drag-area-image"
						src="https://websitedn.yiautos.com/ymc_icon/img_uploader.png"
					/>
					<span class="upload-drag-area-remind">
						点击或将{{ fileType === 'image' ? '图片' : '文件' }}拖拽到这里上传{{
							fileType === 'image' ? '' : ', 文件大小限制200Mb'
						}}
					</span>
					<span v-if="fileType === 'image'" class="upload-drag-area-remark">
						{{ slotContent }}
					</span>
					<span v-if="fileType === 'file' && accept !== '*'" class="upload-drag-area-remark">
						上传文件支持{{ getLimitTips() }}格式
					</span>
				</div>
			</el-upload>
			<div class="upload-list">
				<div class="upload-list-wrapper">
					<div v-for="item in fileList" class="upload-list-item" :key="item.uid">
						<img v-if="fileType === 'image'" class="wait-upload-image-thumb" :src="item.url" />
						<img
							v-else
							class="wait-upload-image-thumb"
							:src="fileDefaultImageSrc(item.url, item.name)"
						/>
						<span class="wait-upload-image-name">{{ item.name }}</span>
						<span style="flex: 1"></span>
						<i class="el-icon-delete-solid wait-upload-image-icon" @click="onDelete(item.uid)" />
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<div class="uploader-footer">
				<el-button
					type="primary"
					size="default"
					style="margin-right: 10px"
					:loading="isLoading"
					@click="onSubmit"
				>
					确 认
				</el-button>
				<el-button size="default" :loading="isLoading" @click="close">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script>
import { defineComponent, computed, reactive, toRefs, inject } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getFileTypeDefaultImage } from './utils'
import _ from 'lodash'
import axios from 'axios'
import dayjs from 'dayjs'
import Compressor from 'compressorjs'
import request from '@/http'
import { getStore } from '@/utils/store'

export default defineComponent({
	name: 'YiFileUploaderDialog',
	components: {},
	emits: ['change'],
	props: {
		max: {
			type: Number,
			default: 9,
		},
		type: {
			type: String,
			required: true,
		},
		widthRatio: {
			type: Number,
		},
		heightRatio: {
			type: Number,
		},
		imgFormat: {
			type: Array,
			default: ['png', 'jpeg', 'jpg', 'gif', 'bmp', 'PNG', 'JPEG', 'JPG', 'GIF', 'BMP'],
		},
		widthSize: {
			type: Number,
		},
		heightSize: {
			type: Number,
		},
		slotContent: {
			type: String,
			default: '上传图片支持 jpg/jpeg/png 格式',
		},
		uploaderSize: {
			type: Number,
			default: 200,
		},
		productType: {
			type: String,
		},
		sourceType: {
			type: String,
		},
		isReal: {
			type: Boolean,
		},
		accept: {
			type: String,
			default: '*',
		},
	},
	setup(props, context) {
		const QINIU_UPLOAD_URL = {
			'oss-test-vsc-master.renrenjia.com.cn': 'https://up-z2.qiniup.com',
			'oss-prod-vsc-master.renrenjia.com.cn': 'https://up-z2.qiniup.com',
			'yiautos-business-oss-1.yiautos.com': 'https://up-z2.qiniup.com',
			'yiautos-business-oss-2.yiautos.com': 'https://up.qiniup.com', // 备用上传地址
		}

		const $message = inject('$message')

		const maxComp = computed(() => {
			// return props.max > 9 ? 9 : props.max
			return props.max
		})

		let uploadCommonKey = ''
		let tokenPromise = null

		const state = reactive({
			isLoading: false,
			isShowUploader: false,
			fileList: [],
			fileType: '',
			spared: false,
		})

		const isProd = process.env.VUE_APP_CONFIG_ENV === 'prod'

		const methods = {
			open(type) {
				state.fileList = []
				state.fileType = type
				state.isShowUploader = true
			},
			close() {
				uploadCommonKey = ''
				state.isShowUploader = false
				state.fileType = ''
				state.fileList = []
			},
			getQiniuCommonToken(bucket_name, uploadKey) {
				if (uploadCommonKey) {
					return uploadCommonKey
				}

				if (tokenPromise) {
					return tokenPromise
				}

				tokenPromise = request({
					url: 'suf4-system-service/oss/getQiniuToken',
					method: 'GET',
					params: {
						bucket_name,
					},
				})
					.then(tokenRes => {
						if (tokenRes.code === 200) {
							uploadCommonKey = tokenRes.data
							return uploadCommonKey
						} else {
							throw new Error(tokenRes.msg)
						}
					})
					.finally(() => {
						tokenPromise = null
					})
				return tokenPromise
			},
			onImagePick: _.debounce(function (file, fileList) {
				const isNormalFile = methods.onLimitFileType(file)
				if (!isNormalFile) {
					fileList.pop()
					$message.error(`上传文件支持${methods.getLimitTips()}格式`)
					return
				}
				state.fileList = fileList
					.filter(file => {
						if (file.size / 1024 / 1024 > props.uploaderSize) {
							return false
						}
						return true
					})
					.map(file => {
						return {
							...file,
							url: window.URL.createObjectURL(file.raw),
							uid: uuidv4(),
						}
					})
				if (state.fileList.length < fileList.length) {
					$message.warning(`文件大小不能超过${props.uploaderSize}Mb, 已为您过滤!`)
				}
			}, 100),
			onExceed: _.debounce(function () {
				$message.error(`超出最大限制数量, 最多只能同时上传${maxComp.value}个资源!`)
			}, 100),
			onSubmit() {
				methods.onUploadConfirm()
			},
			onDelete(uid) {
				const deleteIndex = state.fileList.findIndex(item => {
					return item.uid === uid
				})
				if (deleteIndex !== -1) {
					state.fileList.splice(deleteIndex, 1)
				}
			},
			onUploadConfirm() {
				if (state.fileList.length === 0) return $message.error('请先选择要上传的资源!')
				methods.uploadFn()
			},
			uploadFn(mode = 'default') {
				state.isLoading = true
				Promise.all(
					state.fileList.map(file => {
						return methods.uploadTask(file, mode)
					}),
				)
					.then(uploadResult => {
						const uploadedImages = []
						uploadResult.forEach(result => {
							if (result.code === 200) {
								uploadedImages.push(result.data)
							}
						})
						context.emit('change', uploadedImages)
						state.isLoading = false
						methods.close()
					})
					.catch(e => {
						// 正式环境下做容错处理
						if (process.env.VUE_APP_CONFIG_ENV === 'prod' && !state.spared) {
							state.spared = true
							methods.uploadFn('spare')
						} else {
							state.isLoading = false
							$message.error(e.message)
						}
					})
			},
			// 图片校验
			async imgValidate(params1, params2, file) {
				return await new Promise(function (resolve, reject) {
					let img = new Image()
					img.onload = function () {
						let valid
						// 校验图片比例是否正确
						if (params1 === 'widthRatio' && params2 === 'heightRatio') {
							if (props.widthRatio === props.heightRatio) {
								valid = img.width === img.height
							} else {
								let maxTotal = img.width + img.height
								let fractionTotal = props.widthRatio + props.heightRatio
								valid =
									(props.widthRatio / fractionTotal) * maxTotal == img.width &&
									(props.heightRatio / fractionTotal) * maxTotal == img.height
							}
						}
						// 校验图片尺寸是否正确
						if (params1 === 'widthSize' && params2 === 'heightSize') {
							valid = props.widthSize === img.width && props.heightSize === img.height
						}
						valid ? resolve() : reject()
					}
					img.src = window.URL.createObjectURL(file.raw)
				}).then(
					() => {
						return file
					},
					() => {
						return false
					},
				)
			},
			// 执行图片上传, 为每个单张图片执行上传操作
			async uploadTask(file, mode) {
				return new Promise(async (resolve, reject) => {
					// 获取文件后缀
					const imageSuffixArr = file.name.split('.')
					const imageSuffix = imageSuffixArr[imageSuffixArr.length - 1] // 获取图片结尾
					if (!props.imgFormat.includes(imageSuffix) && state.fileType === 'image') {
						return reject(new Error('格式错误,请上传正确的图片!'))
					}

					if (props.widthRatio && props.heightRatio) {
						const isRatio = await methods.imgValidate('widthRatio', 'heightRatio', file)
						if (!isRatio) {
							return reject(new Error(`图片宽高比为${props.widthRatio}:${props.heightRatio}`))
						}
					}
					if (props.widthSize && props.heightSize) {
						const isRatio = await methods.imgValidate('widthSize', 'heightSize', file)
						if (!isRatio) {
							return reject(new Error(`图片尺寸为${props.widthSize}*${props.heightSize}`))
						}
					}
					// 获取图片名称, 实名场景下取原图片名称, 匿名场景下ft + 8位随机数
					const imageName = props.isReal
						? imageSuffixArr[imageSuffixArr.length - 2].replace(/&/g, '')
						: `ft${Math.random().toString(36).substr(2, 8)}`
					const tenantId = getStore({ name: 'tenantId' }) || '000000'
					// {产品线}/{资源类型}/{年月日期}/{8位随机字符串}/{文件名}.{文件格式}
					const uploadKey = `${tenantId}/${props.productType}/${props.sourceType}/${dayjs().format(
						'YYYYMMDD',
					)}/${Math.random().toString(36).substr(2, 8)}/${imageName}.${imageSuffix}`
					const qiniuCdnPrefix = isProd
						? 'oss-prod-vsc-master.renrenjia.com.cn'
						: 'oss-test-vsc-master.renrenjia.com.cn'
					const bucket_name = isProd ? 'oss-prod-vsc-master' : 'oss-test-vsc-master'
					// 请求Token服务, 获取七牛Token
					try {
						/* const axiosRes = await request({
							url: '/suf4-system-service/oss/getQiniuToken',
							method: 'GET',
							params: {
								bucket_name,
								key: uploadKey,
							},
						}) */
						const keyRes = await methods.getQiniuCommonToken(bucket_name, uploadKey)
						const token = keyRes

						if (state.fileType === 'file') {
							const param = new FormData()
							param.append('token', token)
							param.append('key', uploadKey)
							param.append('file', file.raw)
							const config = {
								headers: { 'Content-Type': 'multipart/form-data' },
							}
							const uploadResult = await axios.post(QINIU_UPLOAD_URL[qiniuCdnPrefix], param, config)
							if (uploadResult.status !== 200) {
								reject(new Error('上传失败!请稍后再试'))
							}
							console.log('上传后的图片url', `https://${qiniuCdnPrefix}/${uploadResult.data.key}`)
							// 返回上传结果
							resolve({
								code: 200,
								data: `https://${qiniuCdnPrefix}/${uploadResult.data.key}`,
							})
						} else {
							if (!['gif', 'GIF'].includes(imageSuffix)) {
								// 执行上传前压缩
								new Compressor(file.raw, {
									quality: 0.8,
									success: async result => {
										// 压缩成功, 执行上传逻辑
										const param = new FormData()
										param.append('token', token)
										param.append('key', uploadKey)
										param.append('file', result)
										const config = {
											headers: { 'Content-Type': 'multipart/form-data' },
										}
										try {
											const uploadResult = await axios.post(
												QINIU_UPLOAD_URL[qiniuCdnPrefix],
												param,
												config,
											)
											if (uploadResult.status !== 200) {
												reject(new Error('上传失败!请稍后再试'))
											}
											// 返回上传结果
											resolve({
												code: 200,
												data: `https://${qiniuCdnPrefix}/${uploadResult.data.key}`,
											})
										} catch (e) {
											reject(new Error('上传失败!请稍后再试'))
										}
									},
									error() {
										reject(new Error('上传失败!请稍后再试'))
									},
								})
							} else {
								// GIF图片不进行压缩
								const param = new FormData()
								param.append('token', token)
								param.append('key', uploadKey)
								param.append('file', file.raw)
								const config = {
									headers: { 'Content-Type': 'multipart/form-data' },
								}
								try {
									const uploadResult = await axios.post(
										QINIU_UPLOAD_URL[qiniuCdnPrefix],
										param,
										config,
									)
									if (uploadResult.status !== 200) {
										reject(new Error('上传失败!请稍后再试'))
									}
									// 返回上传结果
									resolve({
										code: 200,
										data: `https://${qiniuCdnPrefix}/${uploadResult.data.key}`,
									})
								} catch (e) {
									reject(new Error('上传失败!请稍后再试'))
								}
							}
						}
					} catch (e) {
						reject(e)
					}
				})
			},
			fileDefaultImageSrc(url, name) {
				return getFileTypeDefaultImage(url, name)
			},
			onLimitFileType(file) {
				const currentImageType = file && file.name && file.name.split('.').pop().toLocaleUpperCase()
				const accept = props.accept
				if (accept === '*') {
					return true
				}
				const limitFileTypes = accept.split(',').map(val => val.trim().slice(1).toLocaleUpperCase())
				if (limitFileTypes.includes(currentImageType)) {
					return true
				}
				return false
			},
			getLimitTips() {
				console.log('props.accept', props.accept)
				return props.accept.replace(/\./g, '').replace(/,/g, '/')
			},
		}

		return {
			maxComp,
			...toRefs(state),
			...methods,
		}
	},
})
</script>

<style lang="less" scoped>
.yi-file-upload-dialog {
	width: 100%;
	overflow: hidden;
	.uploader-content-box {
		width: 100%;
		height: 630px;
		padding-top: 48px;
		box-sizing: border-box;
		border-top: 1px solid #e5e5e5;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		.upload-body {
			width: 897px;
			height: 250px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.upload-drag-area {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				.upload-drag-area-image {
					width: 120px;
					height: 120px;
					margin-top: 20px;
					margin-bottom: 15px;
				}
				.upload-drag-area-remind {
					font-family: PingFangSC-Regular;
					font-size: 16px;
					color: #222222;
					margin-bottom: 14px;
					line-height: 24px;
				}
				.upload-drag-area-remark {
					font-family: PingFangSC-Regular;
					font-size: 14px;
					color: #999999;
					line-height: 22px;
				}
			}
		}
		.upload-list {
			width: 100%;
			height: 320px;
			box-sizing: border-box;
			overflow-y: auto;
			padding: 20px;
			&::-webkit-scrollbar {
				width: 0;
			}
			.upload-list-wrapper {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				justify-content: flex-start;
				flex-wrap: wrap;
			}
			.upload-list-item {
				width: 420px;
				height: 100px;
				margin-bottom: 20px;
				border-radius: 4px;
				border: 1px solid #eaeaea;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				.wait-upload-image-thumb {
					width: 80px;
					height: 80px;
					margin-left: 10px;
					border-radius: 4px;
					margin-right: 30px;
				}
				.wait-upload-image-name {
					width: 200px;
					font-family: PingFangSC-Regular;
					font-size: 14px;
					color: #333333;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.wait-upload-image-icon {
					font-family: PingFangSC-Regular;
					font-size: 16px;
					color: #f56c6c;
					margin-right: 20px;
					cursor: pointer;
					&:active {
						opacity: 0.8;
					}
				}
			}
			.upload-list-item:nth-child(odd) {
				margin-right: 20px;
			}
		}
	}
}
</style>

<style lang="less">
.yi-file-upload-dialog {
	.el-dialog__body {
		padding: 20px;
	}

	.el-upload-dragger {
		width: 897px;
		height: 250px;
		padding: 0;
	}
}
</style>

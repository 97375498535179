<template>
	<div>
		<template v-if="!item.children">
			<SidebarItemLink :to="item">
				<el-menu-item :index="item.path">
					<el-icon>
						<component :is="'Connection'" />
						<!-- <component :is="item.source ? item.source : isExternal(item.path) ? 'link' : 'menu'" /> -->
					</el-icon>
					<template #title>
						<span>{{ item.name }}</span>
					</template>
				</el-menu-item>
			</SidebarItemLink>
		</template>
		<el-sub-menu v-else :index="item.path">
			<template #title>
				<el-icon><component :is="item.source ? item.source : 'folder'" /></el-icon>
				<span>{{ item.name }}</span>
			</template>
			<SidebarItem
				v-for="child in item.children"
				:key="child.id"
				:item="child"
				:base-path="child.path"
			/>
		</el-sub-menu>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { isExternal } from '@/utils/validate'
import SidebarItemLink from './SidebarItemLink'

export default defineComponent({
	props: {
		item: {
			type: Object,
			required: true,
		},
		basePath: {
			type: String,
			required: true,
		},
	},
	components: {
		SidebarItemLink,
	},
	setup() {
		return {
			isExternal,
		}
	},
})
</script>
<style lang="less">
.el-menu--collapse .el-sub-menu.is-active .el-sub-menu__title {
	color: var(--text-active-color) !important;
}
.simple-mode {
	.el-sub-menu {
		& > .el-sub-menu__title {
			.el-sub-menu__icon-arrow {
				display: none;
			}
		}
	}
}
.el-sub-menu .el-sub-menu__title {
	color: var(--text-color);
	transition: color 0.25s;
	font-weight: 600;
}
.el-sub-menu .el-menu {
	transition: background-color 0.5s;
}
</style>
<style lang="less" scoped>
.el-menu-item,
.el-sub-menu {
	transition: color 0.25s, background-color 0.5s;
	.svg-icon {
		margin-right: 5px;
		font-size: 16px;
	}
}
.el-menu--collapse .el-menu-item .svg-icon,
.el-menu--collapse .el-sub-menu .svg-icon {
	margin: 0;
}
.el-menu-item:hover,
.el-submenu__title:hover {
	color: var(--text-active-color);
	background-color: var(--el-menu-hover-bg-color);
}
.el-menu-item.is-active {
	color: var(--text-active-color);
	font-weight: 600;
	background-color: var(--el-menu-active-color);
}
.simple-mode {
	.submenu-title-noDropdown {
		padding: 0 !important;
		position: relative;

		.el-tooltip {
			padding: 0 !important;
		}
	}

	.el-sub-menu {
		overflow: hidden;

		& > .el-sub-menu__title {
			padding: 0px !important;

			.el-sub-menu__icon-arrow {
				display: none;
			}
			.el-icon {
				margin-right: 0;
			}

			& > span {
				width: 0;
				visibility: hidden;
			}
		}
	}
}
</style>

import request from '@/http'

const prefix = '/api/blade-user'

export const getUserInfo = () => {
	return request({
		url: prefix + '/info',
		method: 'get',
	})
}

export const updatePassword = (oldPassword, newPassword, tenantId) => {
	return request({
		url: 'suf4-user-service/userInfo/changePassword',
		method: 'post',
		data: {
			oldPwd: oldPassword,
			newPwd: newPassword,
		},
		headers: {
			'Tenant-Id': tenantId,
		},
	})
}

export const updateInfo = row => {
	return request({
		url: prefix + '/update-info',
		method: 'post',
		data: row,
	})
}
export const logout = () => {
	return request({
		url: 'suf4-grant/oauth/logout',
		method: 'get',
	})
}

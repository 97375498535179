<template>
	<el-form v-bind="$attrs" ref="formRef" size="mini" :model="model" :rules="mergedRules">
		<el-row :gutter="gutter">
			<div v-for="item in formList" :key="item.prop + item.label">
				<el-col v-if="!item.hide" :span="24">
					<el-form-item
						v-if="!item.hide"
						:prop="item.prop"
						:label="item.label"
						:label-width="item.labelWidth || '120px'"
						style="font-weight: 600"
					>
						<component
							v-if="!item.custom"
							:is="item.component"
							v-model="model[item.prop]"
							v-bind="item.attrs"
							v-on="item.on || {}"
							:size="(item.attrs && item.attrs.size) || 'default'"
							:style="{
								width: `${item.cWidth ? item.cWidth : 190}` + 'px',
								...(item.style || {}),
							}"
						>
							<template v-if="item.component === 'el-select'">
								<el-option
									v-for="option in item.options"
									:key="option.value"
									:label="option.label"
									:value="option.value"
								></el-option>
							</template>
						</component>
						<slot v-else :name="item.prop"></slot>
					</el-form-item>
				</el-col>
			</div>
		</el-row>
	</el-form>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'Form',
	props: {
		formList: [Array],
		model: Object,
		rules: Object,
		gutter: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		mergedRules() {
			const requiredList = this.formList.filter(form => form.required)
			const rules = this.rules || {}
			if (!Object(requiredList).length) return rules
			const required = requiredList.reduce((res, cur) => {
				const required = {
					required: true,
					trigger: 'blur',
					message: cur.attrs ? cur.attrs.message || cur.attrs.placeholder : '',
				}
				const curRule = rules[cur.prop]
				const merged = curRule ? [...curRule, required] : [required]
				res[cur.prop] = merged
				return res
			}, {})
			const res = Object.keys(rules).reduce((acc, key) => {
				if (!acc[key]) acc[key] = rules[key]
				return acc
			}, required)
			return { ...res }
		},
	},
	methods: {
		validate() {
			return new Promise(async (res, rej) => {
				const valid = await this.$refs.formRef.validate().catch(e => rej(e))
				res(valid)
			})
		},
		clearValidate() {
			this.$refs.formRef.clearValidate()
		},
		resetFields() {
			this.$refs.formRef.clearValidate()
			this.$refs.formRef.resetFields()
		},
		resetFieldsItem(code) {
			this.$refs.formRef.clearValidate([code])
		},
	},
})
</script>
